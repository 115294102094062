import { ProfilePageActions, ProfileApiActions } from '../actions';
import { createReducer, on } from '@ngrx/store';
import { Profile } from '../models/profile';
import { APIErrorResponse } from 'src/app/common/http';

export const profilePageFeatureKey = 'profilePage';

export interface State {
    profile:Profile | null,
    validationError: APIErrorResponse | any | null;
    error:string | null
    passSended: boolean | null;
    sended: boolean | null;
    epitAvailable: boolean
}

export const initialState: State = {
    validationError: null,
    profile:null,
    error:null,
    passSended:null,
    sended:null,
    epitAvailable:false,
};

export const reducer = createReducer(
    initialState,
    on(
        ProfileApiActions.getProfileSuccess,
        (state, action) => ({ ...state, profile: action.data})),
    on(ProfileApiActions.changePasswordSuccess,
        (state)=> ({...state, validationError:null, passSended:true})),
    on(ProfileApiActions.validationFailure,
        (state, payload) => ({...state, validationError: payload.error})),
    on(ProfilePageActions.resetSended,
        (state)=> ({...state, passSended:null, sended:null, error:null})),
    // on(
    //     ProfilePageActions.updateProfile,
    //     (state) => ({ ...state, sended: true }))
    on(
        ProfileApiActions.updateProfileSuccess,
        (state) => ({ ...state, sended: true })),
    on(
        ProfileApiActions.updateProfileFailure,
        (state, payload) => ({ ...state, sended: false, error: payload.error})),
    on(ProfileApiActions.getEpitAvailableSuccess,
      (state, action) => ({...state, epitAvailable: action.epitAvailable})),
);

export const selectValidationError = (state: State) => state.validationError;
export const selectError = (state: State) => state.error;
export const selectProfile = (state: State) => state.profile;
export const selectPassSended = (state: State) => state.passSended;
export const selectSended = (state: State) => state.sended;
export const selectEpitAvailable = (state: State) => state.epitAvailable;
