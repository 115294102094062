import {select, Store} from '@ngrx/store';
import {Register} from '../models/register';
import * as fromRegister from '../reducers';
import {RegisterPageActions} from '../actions';
import {Component} from '@angular/core';

@Component({
    template: `
    <app-after-register
      [error]="error$ | async"
      >
    </app-after-register>`
})
export class AfterRegisterPageComponent {
    error$ = this.store.pipe(select(fromRegister.selectRegisterError));
    
    constructor(protected store: Store<fromRegister.State>) {
    }
}
