import {createAction, props} from '@ngrx/store';
import {Verification} from '../models/register';
import { APIErrorResponse } from 'src/app/common/http';
import { Token } from 'src/app/auth/models';

export const verificationSuccess = createAction(
    '[Register/API] User Verification Success',
    props<{verification:Verification}>()
);
export const validationFailure = createAction(
    '[Register/API] Validation Failure',
    props<{error:APIErrorResponse}>()
);
export const verificationFailure = createAction(
    '[Register/API] User Verification Failure',
    props<{error:string}>()
);

export const confirmUserSuccess = createAction(
    '[Register/API] Confirm User Success',
    props<{email:string, accesToken:string}>()
);

export const confirmUserFailure = createAction(
    '[Register/API] Confirm User Failure',
    props<{error:APIErrorResponse}>()
);

export const setPasswordSuccess = createAction(
    '[Register/API] Set Password Success Success'
);

export const setPasswordFailure = createAction(
    '[Register/API] Set Password Failure',
    props<{error:APIErrorResponse}>()
);

