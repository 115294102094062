import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResetPasswordPageComponent, SendLinkPageComponent, SendResetLinkPageComponent} from './containers';
import { AuthGuard } from '../auth/services';

const routes: Routes = [
    {path: 'reset-password/:accesToken', component: ResetPasswordPageComponent},
    {path: 'send-link', component: SendLinkPageComponent},
    {path: 'reset-password', component: SendResetLinkPageComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ResetPasswordRoutingModule {}