<div class="col-12 col-md-10 offset-md-2 bg-white inner-content d-flex justify-content-center flex-column" [ngClass]="{'main': !token }">
    <p>Aplikacja dla telefonów z systemem Android w wersji przedpremierowej dostępna jest pod <a href="http://upload.ddh.pl/dilergo.apk">linkiem</a></p>
    <p>Jeżeli przeglądasz te stronę z urządzenia mobilnego, np.: telefonu, kliknij w link. Twój telefon zacznie pobierać aplikacje.</p>

    
    <p>Następnie zaakceptuj instalację z nieznanych źródeł w swoim telefonie:</p>
    <ul>
    <li>
        1. Otwórz "Ustawienia„
    </li>
    <li>    
        2. Wybierz "Ekran blokady i zabezpieczenia„
    </li>
    <li>
        3. Zaznacz "Nieznane źródła„
    </li>
    </ul>
</div>


