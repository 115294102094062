import {createFeatureSelector, createSelector, Action, combineReducers} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromContact from './contact-page.reducer';

export const contactFeatureKey = 'contact';

export interface ContactState {
    [fromContact.contactPageFeatureKey]: fromContact.State;
}

export interface State extends fromRoot.State {
    [contactFeatureKey]: ContactState;
}

export function reducers(state: ContactState | undefined, action: Action) {
    return combineReducers({
        [fromContact.contactPageFeatureKey]: fromContact.reducer
    })(state, action);
}

export const selectContactState = createFeatureSelector<State, ContactState>(contactFeatureKey);

export const selectContactPageState = createSelector(
    selectContactState,
    state => state.contactPage
);
export const selectSended = createSelector(
    selectContactPageState,
    fromContact.selectSended
);
export const selectError = createSelector(
    selectContactPageState,
    fromContact.selectError
);