import {NgModule} from '@angular/core';
import {ContactRoutingModule} from './contact-routing.module';
import {ContactPageComponent} from './containers';
import { ContactComponent } from './components';
import { EffectsModule } from '@ngrx/effects';
import * as fromContact from './reducers';
import { ContactEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

export const COMPONENTS = [
    ContactPageComponent,
    ContactComponent,
];

@NgModule({
    imports: [
      CommonModule,
      ReactiveFormsModule,
      ContactRoutingModule, 
      StoreModule.forFeature(fromContact.contactFeatureKey, fromContact.reducers),
      EffectsModule.forFeature([ContactEffects])
    ],
    declarations: COMPONENTS,
    providers:[
    ]
})
export class ContactModule {
}