<div class="main-menu" [ngClass]="{'mt--15': mobile}"
[@slideInOut]="{value: (!collapse ) ?'out':'in', params:{pixels:'250px', time:'200ms'}}">
  <ul>
      <li [ngClass]="{'active': curRoute === 'about'}" routerLink="about">
          <div class="icon">
              <!--?xml version="1.0" encoding="UTF-8"?-->
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                      <clipPath id="a">
                          <rect transform="translate(20.5 20)" width="40" height="40" data-name="Rectangle 109"></rect>
                      </clipPath>
                  </defs>
                  <g transform="translate(-20.5 -20)" clip-path="url(#a)" data-name="Mask Group 39">
                      <g transform="translate(20.5 20)">
                          <g data-name="Group 278">
                              <path d="M0,0V40H40V0ZM19.286,8.571a2.857,2.857,0,1,1-2.857,2.857A2.86,2.86,0,0,1,19.286,8.571ZM25,30.714H17.143V17.143h4.286v9.286H25Z" data-name="Path 304"></path>
                          </g>
                      </g>
                  </g>
              </svg>
          </div>
          <span>O aplikacji</span>
      </li>
      <li [ngClass]="{'active': curRoute === 'profile'}" routerLink="profile">
          <div class="icon">
              <!--?xml version="1.0" encoding="UTF-8"?-->
              <svg viewBox="0 0 30.892 40" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(-58.295)">
                      <g transform="translate(58.295 19.961)" data-name="Group 280">
                          <g transform="translate(0)" data-name="Group 279">
                              <path transform="translate(-58.295 -255.5)" d="M77.122,255.5H70.359a12.078,12.078,0,0,0-12.064,12.064v7.975H89.186v-7.975A12.078,12.078,0,0,0,77.122,255.5Z" data-name="Path 305"></path>
                          </g>
                      </g>
                      <g transform="translate(64.412)" data-name="Group 282">
                          <g transform="translate(0)" data-name="Group 281">
                              <path transform="translate(-136.59)" d="M145.92,0a9.329,9.329,0,1,0,9.329,9.329A9.339,9.339,0,0,0,145.92,0Z" data-name="Path 306"></path>
                          </g>
                      </g>
                  </g>
              </svg>

          </div>
          <span>Mój profil</span>
      </li>
      <li [ngClass]="{'active': curRoute === 'faq'}" routerLink="faq">
          <div class="icon">
              <!--?xml version="1.0" encoding="UTF-8"?-->
              <svg viewBox="0 0 42.667 40.1" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(0 -15.401)">
                      <path transform="translate(-214.12 -172.98)" d="m252.52 204.11h-12.694v7.331h-4.289v5.109l-1.946-0.834v8.95h4.289v3.818l8.909-3.818h10v-16.284a4.277 4.277 0 0 0-4.272-4.272zm-4.307 16.571-1.016-2.693h-4.006l-1.027 2.7-2.336-0.89 4.318-11.338h2.124l4.281 11.345z" data-name="Path 307"></path>
                      <path transform="translate(-330.2 -267.52)" d="m360.22 310.03h2.11l-1.05-2.783z" data-name="Path 308"></path>
                      <path transform="translate(-82.194 -74.551)" d="m94.644 103.4-1.5-1.5 1.768-1.768 1.47 1.47a3.5 3.5 0 1 0-1.739 1.8z" data-name="Path 309"></path>
                      <path d="M18.912,39.775V35.957H23.2V15.4H4.272A4.277,4.277,0,0,0,0,19.673V35.957H10Zm-13.94-14.1A6,6,0,1,1,16.032,28.9l1.494,1.494-1.768,1.768-1.478-1.478a6,6,0,0,1-9.308-5Z" data-name="Path 310"></path>
                  </g>
              </svg>

          </div>
          <span>FAQ</span>
      </li>
      <li [ngClass]="{'active': curRoute === 'contact'}" routerLink="contact">
          <div class="icon">
              <!--?xml version="1.0" encoding="UTF-8"?-->
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                      <clipPath id="a">
                          <rect transform="translate(20.5 20)" width="40" height="40" data-name="Rectangle 108"></rect>
                      </clipPath>
                  </defs>
                  <g transform="translate(-20.5 -20)" clip-path="url(#a)" data-name="Mask Group 38">
                      <g transform="translate(20.5 20)">
                          <path d="M40,33.711,33.715,40A33.707,33.707,0,0,1,0,6.3L6.293,0l8.585,8.585-5.6,5.6A16.537,16.537,0,0,0,25.82,30.72l5.595-5.595Z" data-name="Path 303"></path>
                      </g>
                  </g>
              </svg>

          </div>
          <span>Kontakt</span>
      </li>

      <li class="list_logout" (click)="logout.emit()">
          <span>Wyloguj się</span>
      </li>
  </ul>
</div>
