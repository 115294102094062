import {createAction, props} from '@ngrx/store';
import { Profile } from '../models/profile';
import { APIErrorResponse } from 'src/app/common/http';


export const validationFailure = createAction(
    '[Profile/API] Validation Failure',
    props<{error:APIErrorResponse | any}>()
);

export const getProfileSuccess = createAction(
    '[Profile/Api] get Profile Success',
    props<{data:Profile}>()
);
export const getProfileFailure = createAction(
    '[Profile/Api] get Profile Failure',
    props<{error:string}>()
);
export const updateProfileSuccess = createAction(
    '[Profile/Api] update Profile Success',
    props<{data:Profile}>()
);
export const updateProfileFailure = createAction(
    '[Profile/Api] update Profile Failure',
    props<{error:string}>()
);
export const changePasswordSuccess = createAction(
    '[Profile/Api] Change Password Profile Success'
);
export const changePasswordFailure = createAction(
    '[Profile/Api] Change Password Profile Failure',
    props<{error:string}>()
);
export const getEpitStateSuccess = createAction(
  '[Profile/Api] get Epit State Success',
  props<{epit: boolean}>()
);
export const getEpitStateFailure = createAction(
  '[Profile/Api] get Epit State Failure',
  props<{error: string}>()
);
export const toggleEpitStateSuccess = createAction(
  '[Profile/Api] Toggle Epit State Success',
  props<{epit: boolean}>()
);
export const toggleEpitStateFailure = createAction(
  '[Profile/Api] Toggle Epit State Failure',
  props<{error: string}>()
);
export const getEpitSuccess = createAction(
  '[Profile/Api] get Epit Success'
);
export const getEpitFailure = createAction(
  '[Profile/Api] get Epit Failure',
  props<{error: string}>()
);
export const getEpitAvailableSuccess = createAction(
  '[Profile/Api] Get Epit Available Success',
  props<{epitAvailable: boolean}>()
);
export const getEpitAvailableFailure = createAction(
  '[Profile/Api] Get Epit Available Failure',
  props<{error: string}>()
);
