import {Input, Output, EventEmitter, Component} from '@angular/core';
import {Verification} from '../models/register';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Utils} from '../../common/utils';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
    selector: 'app-verification-form',
    template: `
<div class="col bg-white inner-content">

        <p class="section-description">Przed Tobą innowacyjny program ERGO Hestii <span style="color: #7f7f7f">DIL</span><span style="color: #ed0d39">ER GO!</span><br>
          Rejestrując się na niniejszej stronie robisz pierwszy krok, aby otrzymać do niego dostęp.<br />
        <h3>Weryfikacja i Regulamin</h3>

        <form [formGroup]="form" (ngSubmit)="submit()">

          <div class="form-row">
            <div class="form-group col-md-4">
              <input type="text" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.name}" placeholder="Imię" formControlName="name" required>
            </div>
            <div class="form-group col-md-4">
              <input type="text" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.surname}" placeholder="Nazwisko" formControlName="surname" required>
            </div>

            <div class="form-group col-md-4">
              <input type="text" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.pesel}" mask="00000000000" placeholder="PESEL" formControlName="pesel" required>
            </div>
            <div class="form-group col-md-4">
              <input type="tel" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.phone}" prefix="+48" mask="000000000" placeholder="Numer telefonu" formControlName="phone" required>
            </div>

            <div class="form-group col-md-4">
              <input type="email" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.email}" placeholder="Adres e-mail" formControlName="email" required email>
            </div>
            <div class="form-group col-md-4">
              <input type="email" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.confirmEmail}" placeholder="Powtórz adres e-mail" formControlName="confirmEmail" required email>
            </div>
          </div>

          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox" id="terms" formControlName="terms" required>
              <label class="form-check-label" for="terms">Oświadczam, że zapoznałem się z <a href="regulamin.pdf" target="_blank">regulaminem</a> programu DILER GO!.</label>
            </div>
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox" id="rodo" formControlName="rodo" required>
              <label class="form-check-label" for="rodo">Wyrażam zgodę na przetwarzanie przez Sopockie Towarzystwo Ubezpieczeń ERGO Hestia S.A. moich danych osobowych podanych na zgłoszeniu w celu przeprowadzenia i organizacji programu DILER GO!. Administratorem danych osobowych jest Sopockie Towarzystwo Ubezpieczeń ERGO Hestia S.A z siedzibą w Sopocie, ul. Hestii 1, 81-731 Sopot. Administrator wyznaczył inspektora ochrony danych, z którym może się Pani/Pan kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych poprzez adres email: iod@ergohestia.pl oraz pisemnie na adres do korespondencji: ul. Hestii 1, 81-731 Sopot <a href="read-more.pdf" target="_blank">Czytaj więcej ></a>
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox" id="statement" formControlName="statement">
              <label class="form-check-label" for="statement">Wyrażam zgodę na przetwarzanie moich danych osobowych przez Sopockie Towarzystwo Ubezpieczeń ERGO Hestia S.A. w celu otrzymywania informacji marketingowych z wykorzystaniem środków porozumiewania się na odległość (telefon, e-mail, indywidualne Konto Uczestnika udostępnione w Aplikacji Programu), przy użyciu podanych przeze mnie danych kontaktowych.</label>
            </div>
          </div>

          <div class="row">
              <div class="col pb-3">
                <re-captcha formControlName="recaptcha" class="mb-3"></re-captcha>
                <div class="alert alert-warning mt-4" role="alert" *ngIf="!form.controls['recaptcha'].value && send">
                udowodnij że nie jesteś robotem
              </div>
              </div>
              <div class="col">
                <button type="submit" class="btn btn-primary btn-block">Dalej</button>
              </div>
          </div>

        </form>

        </div>`
})

export class VerificationFormComponent {
    @Input() validationError: Verification | null;
    @Input() error: string | null;
    @Output() submitted = new EventEmitter<Verification>();

    send: boolean = false;

    constructor() {}

    form: FormGroup = new FormGroup({
        name: new FormControl(''),
        surname: new FormControl(''),
        pesel: new FormControl(''),
        email: new FormControl('', RxwebValidators.email()),
        confirmEmail: new FormControl('', RxwebValidators.email()),
        phone: new FormControl(''),
        terms: new FormControl(''),
        rodo: new FormControl(''),
        statement: new FormControl(false),
        recaptcha: new FormControl(null, Validators.required)
    });
    ngOnInit(): void {
        this.validationError = null;
        this.error = null;
    }
    submit() {
        this.send = true;
        // console.log(this.form.get('statement').value);

        if (this.form.valid) {
            this.submitted.emit(this.form.value);
        }
        else {
            Utils.validateFormFields(this.form);
        }
    }
}
