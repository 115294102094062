import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResetPasswordRoutingModule} from './reset-password-routing.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ResetPasswordEffects} from './effects';
import * as fromResetPassword from '../reset-password/reducers';
import {ReactiveFormsModule} from '@angular/forms';
import {ResetPasswordPageComponent, SendLinkPageComponent, SendResetLinkPageComponent} from './containers';
import {ResetPasswordFormComponent} from './components/reset-password.component';
import {SendLinkFormComponent} from './components/send-link.component';
import {NgxMaskModule} from 'ngx-mask';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SendResetLinkFormComponent } from './components/send-reset-link.component';


export const COMPONENTS = [
    ResetPasswordPageComponent,
    ResetPasswordFormComponent,
    SendLinkPageComponent,
    SendLinkFormComponent,
    SendResetLinkPageComponent,
    SendResetLinkFormComponent
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        ResetPasswordRoutingModule,
        NgxMaskModule.forRoot(),
        StoreModule.forFeature(fromResetPassword.resetPasswordFeatureKey, fromResetPassword.reducers),
        EffectsModule.forFeature([ResetPasswordEffects])
    ],
    providers: [
  ],
    declarations: COMPONENTS
})
export class ResetPasswordModule {}
