import {createAction, props} from '@ngrx/store';
import {Verification} from '../models/reset-password';
import { APIErrorResponse } from 'src/app/common/http';
import { Token } from 'src/app/auth/models';

export const confirmUserSuccess = createAction(
    '[ResetPassword/API] Confirm User Success',
    props<{email:string, accesToken:string}>()
);

export const confirmUserFailure = createAction(
    '[ResetPassword/API] Confirm User Failure',
    props<{error:APIErrorResponse}>()
);
export const sendLinkSuccess = createAction(
    '[ResetPassword/API] sendLink Success'
);

export const sendLinkFailure = createAction(
    '[ResetPassword/API] sendLink Failure',
    props<{error:string}>()
);
export const sendResetLinkSuccess = createAction(
    '[ResetPassword/API] send Reset Link Success'
);

export const sendResetLinkFailure = createAction(
    '[ResetPassword/API] send Reset Link Failure',
    props<{error:string}>()
);

export const setPasswordSuccess = createAction(
    '[ResetPassword/API] Set Password Success Success'
);

export const setPasswordFailure = createAction(
    '[ResetPassword/API] Set Password Failure',
    props<{error:APIErrorResponse}>()
);
export const validationFailure = createAction(
    '[ResetPassword/API] Validation Failure',
    props<{error:APIErrorResponse}>()
);

