import {Component} from '@angular/core';

@Component({
    template: `
      <app-about>
      </app-about>`
})
export class AboutPageComponent{
    constructor() {}
}
