
      <div class="col-12 col-md-10 offset-md-2 bg-white inner-content">

        <h3 class="page-title text-center">Pytania i odpowiedzi</h3>

<div class="container container-narrow">
  <!-- <h4 class="faq-separator">FAQ ERGO Hestia DILER GO!:</h4> -->
  <accordion [isAnimated]="true">
    <accordion-panel class="panel-title" heading="Co to jest aplikacja DILER GO!? ">
      W pełni mobilny program wsparcia sprzedaży, w którym pośrednicy sprzedający ubezpieczenia w pakietach dilerskich ERGO Hestii mogą brać udział w akcjach motywacyjnych za pomocą aplikacji na telefony. Program łączy wszystkie aktywności promocyjne w jednej aplikacji dostępnej zarówno na telefony z systemem iOS i Android.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jakie systemy sprzedażowe mogą być objęte akcjami motywacyjnymi?">
      Cumulus Diler, EBIS, MBU-POS, EIS-Honda, Hyundai , WTW-SOP Renault-Dacia i WTW-SOP Nissan.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Kto może być uczestnikiem Programu DILER GO!?">
      Agenci ubezpieczeniowi i ich OFWCA sprzedający ubezpieczenia w pakietach dilerskich ERGO Hestii.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jakie są zalety aplikacji DILER GO!?">
      <div class="pb-1">
        <ul class="point-list">
          <li>jeden regulamin dla wszystkich aktywności </li>
          <li>spersonalizowane konkursy i akcje motywacyjne</li>
          <li>bieżące śledzenie wyników akcji motywacyjnych</li>
          <li>powiadomienia o nowych i trwających akcjach</li>
          <li>możliwość szybkiej wypłaty nagrody</li>
        </ul>
      </div>
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Co to są Akcje? ">
      Akcje to akcje motywacyjne lub konkursy, w których po spełnieniu określonych warunków, uczestnik otrzymuje nagrody. Akcjami mogą być również quizy i ankiety. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Czy Akcje są objęte podatkiem?">
      Akcje tego typu, zgodnie z obowiązującymi przepisami, muszą zostać opodatkowane podatkiem dochodowym. To oznacza, że osoby prowadzące działalność gospodarczą muszą samodzielnie rozliczyć się z przychodu. Osoby fizyczne, które nie prowadzą działalności gospodarczej otrzymają od nas, na początku każdego roku kalendarzowego, deklarację PIT (w formie elektronicznej jeśli wyrażą na to stosowną zgodę). 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jak długo trwają akcje? ">
      Wszystkie zasady danej akcji, użytkownik pozna bezpośrednio w aplikacji DILER GO!. Akcje mogą mieć różne okresy trwania, w zależności od wymaganych do osiągnięcia celów. Użytkownik aplikacji DILER GO! ma na bieżąco dostęp do listy aktywnych i zakończonych akcji. Dzięki temu dokładnie wie, do jakich akcji został zaproszony, do jakich jeszcze nie przystąpił, a w których już bierze udział.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jak mogę przystąpić do akcji?">
      Należy wejść w daną akcję, kliknąć „Szczegóły akcji” i zapoznawszy się z nimi  kliknąć „Przystępuję do Akcji”. Dołączyć można tylko do akcji aktywnych dla danego użytkownika. Warunkiem koniecznym jest oczywiście wcześniejsze zarejestrowanie się do aplikacji. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jak mam sprawdzić swoje wyniki w akcji?">
      Na stronie głównej aplikacji lub w zakładce „Akcje” wyświetlany jest licznik realizacji celu danej akcji. Dodatkowo aplikacja, po wyrażeniu zgody na otrzymywanie wiadomości push, będzie wysyłała powiadomienia za każdym razem, kiedy wystawiona polisa zostanie zaliczona do akcji. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Nie otrzymuję powiadomień na ekran telefonu, co mam zrobić? ">
      Przejdź do instrukcji obsługi aplikacji znajdującej się na stronie www.dilergo.ergohestia.pl. Po zalogowaniu się na stronie, przejdź do zakładki „Poznaj aplikację”. Tam znajdziesz informacje jak uruchomić powiadomienia na telefonie. <br/>
<br/>
W menu aplikacji, tuż obok Twojego imienia i nazwiska znajdziesz symbol kółka zębatego.
Po kliknięciu rozwinie się listwa z ustawieniami. Po lewej stronie znajdziesz suwaczek „Powiadomienia” przesuń go na pozycję „ON”.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Gdzie znajdę historyczne akcje?">
      Historyczne akcje znajdziesz w zakładce „Akcje zakończone”. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Co to są PLN?">
      PLN to nagrody, które uczestnik Akcji otrzyma za realizację danego celu. 1 PLN to 1 złotówka. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jak mogę sprawdzać saldo mojej karty przedpłaconej?">
      Saldo można sprawdzać po zarejestrowaniu karty na stronie <a href="https://dlaciebie.sodexo.pl/"
         target="_blank">https://dlaciebie.sodexo.pl/</a> oraz w aplikacji SODEXO,
      którą można pobrać ze strony.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Czy stan zebranych PLN może zostać wyzerowany?">
      Tak, wszystkie zgromadzone w danym roku PLN trzeba wykorzystać do 31 stycznia roku następnego. Stan PLN zostanie wyzerowany.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jak mogę sprawdzić, ile mam uzbieranych PLN?">
      Na stronie głównej aplikacji w prawym górnym rogu wyświetla się aktualna ilość „PLN”. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading=" Jak mogę wypłacić PLN?">
      W zakładce „Kasa” należy podać liczbę PLN, którą chcesz wypłacić. Po wykonaniu tej operacji dostaniesz e-mail z prośbą o potwierdzenie zamówienia wypłaty. Wystarczy kliknąć w przesłany link. Zostaniesz przekierowany na stronę www.dilergo.ergohestia.pl. Warunkiem wypłaty środków jest potwierdzenie danych do rozliczenia. Po kliknięciu w „Potwierdź”, środki w ciągu kilku dni znajdą się na Twoim koncie. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Ile maksymalnie mogę zdobyć PLN?">
      Nie ma ograniczenia dotyczącego liczby PLN, które mogą być zebrane w roku kalendarzowym. Zależy to wyłącznie od aktywności uczestnika i realizacji celów stawianych w kolejnych akcjach. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading=" Jeśli jestem Agentem i moja OFWCA uzbiera PLN, czy ja również coś otrzymam?">
      Nie, PLN są przypisane tylko do użytkownika, który je zgromadził. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Czy mogę przekazać PLN innemu uczestnikowi?">
      PLN są przypisane tylko do użytkownika, który je zgromadził. Nie ma możliwości przekazywania PLN między użytkownikami.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Jeśli jestem Agentem to czy mogę kontrolować ilość PLN, jaką zgromadziła moja OFWCA?">
      Nie ma takiej możliwości.
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Czy dostanę PIT?">
      Tak, jeśli jesteś osobą fizyczną dla której nagrody będą stanowiły dochód z innych źródeł np. osobą zatrudnioną przez agenta ubezpieczeniowego na podstawie umowy o pracę lub umowy cywilnoprawnej. PIT otrzymasz drogą elektroniczną jeśli wyrazisz na to zgodę w okresie rozliczania deklaracji. W innym przypadku PIT otrzymasz drogą korespondencyjną. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Czy muszę co roku pamiętać, żeby dołączyć do programu?">
      Nie, wystarczy raz przystąpić do programu. 
    </accordion-panel>
  </accordion>
  <accordion [isAnimated]="true">
    <accordion-panel heading="Co z Moimi Punktami?">
      Nowe rozwiązanie zastąpi platformę Moje Punkty. Uczestnicy będą mieli czas na wykorzystanie zgromadzonych punktów do 30 października 2020 r. <br/>
      <strong>Uwaga!</strong> Po tym okresie dostęp do programu zostanie wyłączony, a przywrócenie punktów nie będzie możliwe.
    </accordion-panel>
  </accordion>
</div>

</div>