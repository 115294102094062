import {Component} from '@angular/core';

@Component({
    template: `
      <app-main>
      </app-main>`
})
export class MainPageComponent{
    constructor() {}
}
