import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import * as fromRoot from '../../reducers';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {LoginPageActions, AuthApiActions, AuthActions} from '../actions';
import {Credentials, Token} from '../models/auth';
import {AuthService} from '../services';
import * as fromRegister from '../reducers/';
import {Store, select} from '@ngrx/store';
import {ProfilePageActions} from "../../profile/actions";

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginPageActions.login),
            exhaustMap((auth: Credentials) =>
                this.authService.login(auth).pipe(
                    map((token: Token) => AuthApiActions.loginSuccess(token)),
                    catchError((error: string) => of(AuthApiActions.loginFailure({error})))
                )
            )
        )
    );

    // loginSuccess$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(AuthApiActions.loginSuccess),
    //         withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUrlToken))),
    //         map(action=>action[1]),
    //         tap((token) => {
    //             if(token)
    //                 this.router.navigate(['/confirm', token]);
    //             else
    //                 this.router.navigate(['/welcome']);
    //         })
    //     ),
    //     {dispatch: false}
    // );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.loginSuccess),
      mergeMap(() => [AuthActions.redirectLoginSuccess(), ProfilePageActions.getEpitAvailable()])
    )
  );
  redirectLoginSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.redirectLoginSuccess),
        withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUrlToken))),
        map(action=>action[1]),
        tap((token) => {
          if(token)
            this.router.navigate(['/confirm', token]);
          else
            this.router.navigate(['/welcome']);
        })
      ),
    {dispatch: false}
  );

    loginFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthApiActions.loginFailure),
            tap(action => {
                //                if (action.error) this.store.dispatch(LayoutActions.showAlert({
                //                    title: "Uwaga",
                //                    text: action.error == "Invalid grant: user credentials are invalid" ? "Podano zły login lub hasło" : action.error, singleButtonText: "Ok",
                //                    action: () => {}
                //                }))
            })
        ),
        {dispatch: false}
    );

    loginRedirect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthActions.loginRedirect,
                AuthActions.logout
            ),
            tap(() => {
                this.router.navigate(['/login']);
            })
        ),
        {dispatch: false}
    );
    loginRedirectParam$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthActions.loginRedirectParam,
            ),
            map(action=>action.param),
            tap((param) => {
                this.router.navigate(['/login', param]);
            })
        ),
        {dispatch: false}
    );

    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginPageActions.changePassword),
            exhaustMap(action =>
                this.authService.changePassword(action.credentials, action.resetPassword).pipe(
                    map(() => AuthApiActions.changePasswordSuccess()),
                    catchError((error: string) => of(AuthApiActions.changePasswordFailure({error})))
                )
            )
        )
    );

    changePasswordSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthApiActions.changePasswordSuccess),
            tap(() => {
                this.router.navigate(['/welcome']);
            })
        ),
        {dispatch: false}
    );

    clientCredentialsToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.clientCredentialsToken),
            exhaustMap(() =>
                this.authService.clientCredentialsToken().pipe(
                    map((token: Token) => AuthApiActions.clientCredentialsTokenSuccess(token))
                )
            )
        )
    );

    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.refreshToken),
            exhaustMap((token: Token) =>
                this.authService.refreshToken(token).pipe(
                    map((token: Token) => AuthApiActions.refreshTokenSuccess(token))
                )
            )
        )
    );
    setFirst$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginPageActions.setFirst),
            tap(()=>{
                this.router.navigate(['/login']);
            })
        ),{dispatch:false}
    );

    constructor(
        private store: Store<fromRegister.State>,
        private actions$: Actions,
        private authService: AuthService,
        private router: Router
    ) {}
}
