import {Component} from '@angular/core';
import {AppStore,GooglePlay} from '../../common/variables'

@Component({
    selector: 'app-about',
    templateUrl:'./about.component.html',
    styles:[`
    .list-group{
        padding-left: 20px;
    }
      .shop-icon {
        height: 70px;
      }
      ol.manual-list {
        list-style-type: decimal;
      }
      ol.manual-list li {
        line-height: 1.5em;
        margin: 10px 0;
      }
      ol.manual-sublist {
        list-style-type: lower-alpha;
      }

      p {
        line-height: 1.5em;
        margin: 0;
        padding: 0;
      }
    `]
})
export class AboutComponent {
  AppStore = AppStore;
  GooglePlay = GooglePlay;

  install=false;
  nawigacja=false;
  main=false;
  specialActions=false;
  coins=false;
  messages=false;
  card=false;
}
