import { Component, HostListener } from '@angular/core';
import { Observable, Subscription, Subject, fromEvent } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from '../../auth/reducers';
import * as fromProfile from '../../profile/reducers';
import { AuthActions } from 'src/app/auth/actions';
import { debounceTime } from 'rxjs/operators';
import {ProfilePageActions} from "../../profile/actions";
// import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  template: `
<nav class="navbar navbar navbar-expand-lg static-top">
  <div class="container" [ngClass]="{'flex-column': ismain}">

    <a routerLink="/" class="navbar-brand" [ngClass]="{'mt-5': ismain, 'mx-auto': ismain}">
        <img src="assets/gfx/logo.svg" alt="">
    </a>
    <!-- ukryć logo i usunąć ukrywanie na mobile W mainie -->
    <button class="navbar-toggler" *ngIf="!hideHamburger" [ngClass]="{'collapsed': collapse}" (click)="collapse?collapse=false:collapse=true" type="button" data-toggle="collapse" data-target="#navbarResponsive"
        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <div class="hamburger menu-toggle" [ngClass]="{'open': collapse}" id="hamburger">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
        </div>
    </button>

    <app-topnav [collapse]="collapse" [mobile]="mobile" [logged]="loggedIn$ | async" [curRoute]="curRoute$ | async" [ismain]="ismain" [epitAvailable]="epitAvailable$ | async" (logout)="logout()" (epitDownload)="epitDownload()"></app-topnav>

  </div>
</nav>

 <main class="main-content">

  <div class="container">
    <div class="row">
    <app-menu [curRoute]="curRoute$ | async" *ngIf="(loggedIn$ | async) && hideMenu===false" [collapse]="collapse" [mobile]="mobile" (logout)="logout()"></app-menu>
    <router-outlet></router-outlet>
    </div>
  </div>
 </main>

 <footer class="footer" [ngClass]="{'main': ismain}">
  <div class="container text-center">
    <p class="pt-4 pb-4">2020 &copy; ERGO Hestia. Wszelkie prawa zastrzeżone.</p>
  </div>
</footer>`
})
export class AppComponent {
  public loggedIn$: Observable<boolean>;
  public curRoute$: Observable<string>;
  epitAvailable$: Observable<boolean>;
  curRoute:string;
  hideHamburger:boolean;
  collapse: boolean = true;
  mobile: boolean = false;
  sub: Subscription;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  ismain = false;
  hideMenu = false;

  constructor(private store: Store<fromRoot.State & fromAuth.State & fromProfile.State> ) {
    this.loggedIn$ = this.store.pipe(select(fromAuth.selectLoggedIn));
    this.curRoute$ = this.store.pipe(select(fromRoot.getCurrentUrl));
    this.epitAvailable$ = this.store.pipe(select(fromProfile.selectEpitAvailable));
  }
  ngOnInit(): void {
    this.detectMobile();
    this.sub = this.curRoute$.subscribe((r) => {
      this.curRoute = r;
      // console.log(r);
      // const gtmTag = {
      //   event: 'page',
      //   pageName: r
      // };
      // this.gtmService.pushTag(gtmTag);
      gtag('config', 'UA-179009744-1', {'page_path':'/'+r});

      if (r === '') {
        this.collapse = true;
        this.ismain = true;
        if(this.mobile) this.hideHamburger = true;
      }else{
        this.ismain = false;
        this.hideHamburger = false;
      }
      if(r == 'confirm'){
        this.hideMenu = true;
      }else{
        this.hideMenu = false;
      }

    });
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(100)).subscribe(evt => {
      this.detectMobile();
    })
  }
  detectMobile() {
    if (document.body.offsetWidth <= 420) {
      if(!this.hideHamburger)this.collapse = false;
      this.mobile = true;
    } else {
      this.mobile = false;
      this.collapse = true;
    }
  }
  logout() {
    this.store.dispatch(AuthActions.logout());
  }
  epitDownload() {
    this.store.dispatch(ProfilePageActions.getEpit());
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.resizeSubscription$.unsubscribe();
  }
}
