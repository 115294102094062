import {WithdrawalApiActions, WithdrawalPageActions} from '../actions';
import {createReducer, on} from '@ngrx/store';

export const withdrawalPageFeatureKey = 'withdrawalPage';

export interface State {
    error: string | null;
}

export const initialState: State = {
    error: null
};

export const reducer = createReducer(
    initialState,
    on(WithdrawalApiActions.CheckLinkFailure,
        (state, action)=>({...state, error:action.error}))
);

export const selectError = (state: State) => state.error;
