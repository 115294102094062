import {ContactPageActions, ContactApiActions} from '../actions';
import {createReducer, on} from '@ngrx/store';
import {Contact} from '../models';

export const contactPageFeatureKey = 'contactPage';

export interface State {
    sended: boolean | null;
    error: string | null;
}

export const initialState: State = {
    sended: false,
    error: null
};

export const reducer = createReducer(
    initialState,
    on(
        ContactPageActions.setSended,
        (state, action) => ({...state, sended: true})),
    on(
        ContactPageActions.resetSended,
        (state, action) => ({...state, sended: false, error: null})),
    on(
        ContactApiActions.sendMessageFailure,
        (state, action) => ({...state, error: action.error})),
    on(
        ContactApiActions.sendMessageSuccess,
        (state, action) => ({...state, error: null}))
);

export const selectSended = (state: State) => state.sended;
export const selectError = (state: State) => state.error;