import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType, createEffect} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap, withLatestFrom, delay} from 'rxjs/operators';
import {RegisterPageActions, RegisterApiActions} from '../actions';
import {RegisterService} from '../services/register.service';
import {Verification, Password} from '../models/register';
import {Store, select} from '@ngrx/store';
import * as fromRegister from '../reducers';
import * as fromRoot from '../../reducers';
import {errorType, APIErrorResponse} from 'src/app/common/http/response';
import { LoginPageActions } from 'src/app/auth/actions';

@Injectable()
export class RegisterEffects {

    verification$ = createEffect(() => this.actions$.pipe(
        ofType(RegisterPageActions.verification),
        map(action => action.verification),
        exhaustMap((verification: Verification) =>
            this.registerService.verification(verification).pipe(
                exhaustMap(() => of(RegisterApiActions.verificationSuccess({verification}))),
                catchError((error: errorType) => {
                    if (error.validationError) {
                        return of(RegisterApiActions.validationFailure({
                            error: JSON.parse(error.validationError)
                        }))
                    } else if (error.requestError) {
                        let alert;
                        switch (error.requestError) {
                            case 'user_does_not_exists':
                            alert = `
                                <h3 class="pt-100-lg mb-0"><span class="text-red">Nie udało się</span> zweryfikować Twojej tożsamości w ERGO Hestii.</h3>
                                <h3 class="pt-3">
                                  Sprawdź czy formularz nie zawiera błędnych danych, a w przypadku dalszych problemów skontaktuj się ze swoim
                                  Regionalnym Kierownikiem Sprzedaży.
                                </h3>
                                <p class="pb-4 pt-3">Twoje dane osobowe z poprzedniego kroku rejestracji do ERGO Hestia DILER GO! zostały usunięte z naszych systemów i nie będą dalej przetwarzane.</p>
                            `;
                                break;
                            case 'user_already_registered':
                                alert = `
                                    <h3 class="pt-100-lg mb-0"><span class="text-red">Użytkownik</span> jest już zarejestrowany.</h3>
                                    <h3 class="pt-3">
                                        Sprawdź czy formularz nie zawiera błędnych danych, a w przypadku dalszych problemów skontaktuj się ze swoim
                                        Regionalnym Kierownikiem Sprzedaży..
                                    </h3>
                                    <p class="pb-4 pt-3">Twoje dane osobowe z poprzedniego kroku rejestracji do ERGO Hestia DILER GO! zostały usunięte z naszych systemów i nie będą dalej przetwarzane.</p>
                                `;
                                break;
                            case 'EMAIL_EXISTS':
                                alert = `<h3 class="pt-100-lg mb-0"><span class="text-red">Podany adres email</span> jest już w użyciu.</h3>
                                    <h3 class="pt-3">
                                        Sprawdź czy formularz nie zawiera błędnych danych, a w przypadku dalszych problemów skontaktuj się ze swoim
                                        Regionalnym Kierownikiem Sprzedaży..
                                    </h3>
                                    <p class="pb-4 pt-3">Twoje dane osobowe z poprzedniego kroku rejestracji do ERGO Hestia DILER GO! zostały usunięte z naszych systemów i nie będą dalej przetwarzane.</p>
                                `;
                                break;
                            default:
                                alert = `<h3 class="pt-100-lg mb-0"><span class="text-red">Wystąpił nieznany błąd!</span>.</h3>
                                <h3 class="pt-3">
                                    Sprawdź czy formularz nie zawiera błędnych danych, a w przypadku dalszych problemów skontaktuj się ze swoim
                                    Regionalnym Kierownikiem Sprzedaży..
                                </h3>
                                <p class="pb-4 pt-3">Twoje dane osobowe z poprzedniego kroku rejestracji do ERGO Hestia DILER GO! zostały usunięte z naszych systemów i nie będą dalej przetwarzane.</p>
                            `;
                                break;
                        }
                        return of(RegisterApiActions.verificationFailure({error: alert}));
                    }
                })
            )
        )
    ));

    verificationRedirect$ = createEffect(() => this.actions$.pipe(
        ofType(RegisterApiActions.verificationSuccess, RegisterApiActions.verificationFailure),
        tap(() => this.router.navigate(['/register/complete']))
    ), {dispatch: false});

    confirmUser$ = createEffect(() => this.actions$.pipe(
        ofType(RegisterPageActions.confirmUser),
        map((action=>action.accesToken)),
        exhaustMap((accesToken: string) =>
            this.registerService.confirmUser(accesToken).pipe(
                map((email:string) => RegisterApiActions.confirmUserSuccess({email, accesToken})),
                catchError((error: APIErrorResponse) =>
                       of(RegisterApiActions.confirmUserFailure({
                           error
                       }))
                ))
        )
    ));
    setPassword$ = createEffect(() => this.actions$.pipe(
        ofType(RegisterPageActions.setPassword),
        withLatestFrom(this.store.select(fromRegister.selectUser), this.store.select(fromRegister.selectToken)),
        map(action=> {return { accesToken: (action[1] as any).accesToken, email:action[1].email, password: action[0].password, confirmPassword: action[0].confirmPassword  } as Password}),
        exhaustMap((password: Password) =>
            this.registerService.setPassword(password).pipe(
                map((res:boolean) =>
                    res? RegisterApiActions.setPasswordSuccess():
                    RegisterApiActions.setPasswordFailure(null)
                ),
                catchError((error: errorType) => {
                    // console.log(error);
                    if (error.validationError) {
                        return of(RegisterApiActions.validationFailure({
                            error: JSON.parse(error.validationError)
                        }))
                    } else if (error.requestError) {
                       RegisterApiActions.setPasswordFailure(null)
                    }
                })
            )
        )
    ));

    setFirstLogin$ = createEffect(() => this.actions$.pipe(
        ofType(RegisterApiActions.setPasswordSuccess),
        map(()=>LoginPageActions.setFirst())
    ));

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private registerService: RegisterService,
        private router: Router
    ) {}
}
