import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {GooglePageComponent} from './containers';

const routes: Routes = [{path: 'google', component: GooglePageComponent }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GoogleRoutingModule {}