import {createSelector, createFeatureSelector, combineReducers, Action} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from './auth.reducer';
import * as fromLoginPage from './login-page.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
    [fromAuth.statusFeatureKey]: fromAuth.State;
    [fromLoginPage.loginPageFeatureKey]: fromLoginPage.State;
}

export interface State extends fromRoot.State {
    [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
    return combineReducers({
        [fromAuth.statusFeatureKey]: fromAuth.reducer,
        [fromLoginPage.loginPageFeatureKey]: fromLoginPage.reducer,
    })(state, action);
}

export const selectAuthState = createFeatureSelector<State, AuthState>(authFeatureKey);

export const selectAuthStatusState = createSelector(
    selectAuthState,
    state => state.status
);

export const selectToken = createSelector(selectAuthStatusState, fromAuth.selectToken);

export const selectLoggedIn = createSelector(selectToken, token => !!token && token.refresh_token != null);

export const selectLoginPageState = createSelector(
    selectAuthState,
    (state: AuthState) => state.loginPage
);
export const selectLoginPageError = createSelector(
    selectLoginPageState,
    fromLoginPage.selectError
);
export const selectFirst = createSelector(
    selectLoginPageState,
    fromLoginPage.selectFirst
);
