import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthActions} from '../actions';
import * as fromAuth from '../reducers';
import {Token} from '../models/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private store: Store<fromAuth.State>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        let logged = route.data["logged"] as boolean;
        return this.store.pipe(
            select(fromAuth.selectToken),
            map((token: Token) => {
                if (token && token.refresh_token && logged) {
                    this.router.navigate(['/welcome']);
                    return false;
                }
                else if (token && !token.refresh_token && !logged) {
                    if(route.url[0].path == 'confirm'){
                        this.store.dispatch(AuthActions.loginRedirectParam({param:route.url[1].path}));
                        return false;
                    }
                }
                else if (!token && !logged) {
                    this.store.dispatch(AuthActions.loginRedirect());
                    return false;
                }
                return true;
            }),
            take(1)
        );
    }
}
