import { select, Store } from '@ngrx/store';
import * as fromResetPassword from '../reducers';
import { ResetPasswordPageActions } from '../actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    template: `
    <app-send-reset-link
      (submitted)="onSubmit($event)"
      [sended]="sended$ | async"
      [error]="error$ | async">
    </app-send-reset-link>`
})
export class SendResetLinkPageComponent implements OnInit, OnDestroy {
    sended$ = this.store.pipe(select(fromResetPassword.selectSended));
    user$ = this.store.pipe(select(fromResetPassword.selectUser));
    error$ = this.store.pipe(select(fromResetPassword.selectResetPasswordError));
    
    constructor(protected store: Store<fromResetPassword.State>, private route: ActivatedRoute) {
    }
    ngOnInit(): void {
    }
    onSubmit(email: any) {
        this.store.dispatch(ResetPasswordPageActions.sendResetLink({ email: email.email }));
    }
    ngOnDestroy(): void {
        this.store.dispatch(ResetPasswordPageActions.resetSended());
    }
}
