import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {FaqRoutingModule} from './faq-routing.module';
import {FaqPageComponent} from './containers';
import {FaqComponent} from './components';

export const COMPONENTS = [
    FaqPageComponent,
    FaqComponent
];

@NgModule({
    imports: [
        FaqRoutingModule,
        BrowserAnimationsModule,
        AccordionModule.forRoot()
    ],
    declarations: COMPONENTS,
    providers: [
    ]
})
export class FaqModule {
    
}