import {Component} from '@angular/core';
import * as fromAuth from '../../auth/reducers';
import {Store, select} from '@ngrx/store';

@Component({
    template: `
      <app-google [token]="token$ | async">
      </app-google>`
})
export class GooglePageComponent{
    token$ = this.store.pipe(select(fromAuth.selectToken));
    
    constructor(private store: Store<fromAuth.State>) {
    }
}