import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions} from '@ngrx/effects';
import * as fromWelcome from '../reducers';
import {Store} from '@ngrx/store';
import {WelcomeService} from '../service';

@Injectable()
export class WelcomeEffects {
    

    constructor(
        private store: Store<fromWelcome.State>,
        private actions$: Actions,
        private welcomeService: WelcomeService,
        private router: Router
    ) {}
}
