import {WelcomeApiActions, WelcomePageActions} from '../actions';
import {createReducer, on} from '@ngrx/store';

export const welcomePageFeatureKey = 'welcomePage';

export interface State {
    error: string | null;
}

export const initialState: State = {
    error: null
};

export const reducer = createReducer(
    initialState,
    
);

export const selectError = (state: State) => state.error;
