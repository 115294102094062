export const voivodeships:string[]=[
    "Wielkopolskie",
    "Kujawsko-pomorskie",
    "Małopolskie",
    "Łódzkie",
    "Dolnośląskie",
    "Lubelskie",
    "Lubuskie",
    "Mazowieckie",
    "Opolskie",
    "Podlaskie",
    "Pomorskie",
    "Śląskie",
    "Podkarpackie",
    "Świętokrzyskie",
    "Warmińsko-Mazurskie",
    "Zachodniopomorskie"
];