import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { APIErrorResponse } from 'src/app/common/http';
import { Profile, ChangePassword } from '../models/profile';
import { apiUrl } from 'src/app/common/variables';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    constructor(private http: HttpClient) { }

    public getProfile(): Observable<Profile> {
        const params = new HttpParams();
        // let tmpRes:Profile = {
        //     name:"Adam",
        //     surname:"Jujka",
        //     mail:"a.jujka@ddh.pl",
        // }
        // return of(tmpRes)
        return this.http.post<Profile>(apiUrl + 'user/profile', params)
        .pipe(
            map((res: Profile) => {
                // return {...res, phone:'123321123'};
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError(error.error.type);
            })
        );
    }
    public updateProfile(data:Profile): Observable<boolean> {
        var params = new HttpParams();
        let tmpData:Profile ={...data};
        for (const [key, value] of Object.entries(tmpData)) {
                if(key != 'name' && key != 'surname' && key != 'email')
                    params = params.append(key, value);
          }
        return this.http.post<boolean>(apiUrl + 'user/change-profile', params)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError(error.error.type);
            })
        );
    }
    public changePassword(credentials:ChangePassword): Observable<boolean> {
        var params = new HttpParams();

        for (const [key, value] of Object.entries(credentials)) {
            params = params.append(key, value);
          }
        return this.http.post<boolean>(apiUrl + 'user/change-password-profile', params)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError(error.error.type);
            })
        );
    }

  public getEpit(): Observable<any> {
    const params = new HttpParams();
    return this.http.post<any>(apiUrl + 'user/epit-download', params, {observe: 'response', responseType: 'blob' as 'json', withCredentials: true})
      .pipe(
        map((res: any) => {
          // console.log(res);
          // console.log(res.headers.keys());
          let content = decodeURIComponent(res.headers.get('content-disposition'));
          let index = content.indexOf('filename=');
          let name = content.substring(index + 10, content.indexOf('.pdf'));
          console.log(name);
          saveAs(res.body, name);
          return res;
        }),
        catchError((error: APIErrorResponse) => {
          console.log(error);
          return throwError(error.error.type);
        })
      );
  }

  public getEpitAvailable(): Observable<any> {
    const params = new HttpParams();
    return this.http.post<any>(apiUrl + 'user/epit-available', params)
      .pipe(
        map((res: boolean) => {
          return res;
        }),
        catchError((error: APIErrorResponse) => {
          return throwError(error.error.type);
        })
      );
  }
}
