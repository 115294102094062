import {RegisterApiActions, RegisterPageActions} from '../actions';
import {createReducer, on} from '@ngrx/store';
import {Verification} from '../models/register';
import {APIErrorResponse} from 'src/app/common/http';

export const registerPageFeatureKey = 'registerPage';

export interface State {
    validationError: APIErrorResponse | null;
    error: string | null;
    user: Verification | null;
    accesToken:string;
}

export const initialState: State = {
    validationError: null,
    error: null,
    user: null,
    accesToken:null
};

export const reducer = createReducer(
    initialState,
    on(
        RegisterPageActions.verification,
        state => ({...state, error: null})),
    on(
        RegisterApiActions.validationFailure,
        RegisterApiActions.setPasswordFailure,
        (state, payload) => ({...state, validationError: payload.error})),
    on(
        RegisterApiActions.verificationFailure,
        (state, payload) => ({...state, error: payload.error})),

    on(
        RegisterApiActions.confirmUserSuccess,
        (state, payload) => ({...state, error: null, user: {...state.user, email:payload.email, accesToken:payload.accesToken}}))
);

export const selectValidationError = (state: State) => state.validationError;
export const selectError = (state: State) => state.error;
export const selectUser = (state: State) => state.user;
export const selectToken = (state: State) => state.accesToken;
