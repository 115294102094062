import {Component, Input, Output, EventEmitter} from '@angular/core';
import { SlideInOutAnimation } from '../../animations/slideinout.animations';

@Component({
    selector: 'app-menu',
    templateUrl:'./menu.component.html',
    animations:[SlideInOutAnimation],
    styles:[`
      :host{
        width:100%;
      }
      .mt--15{
        margin-top: -15px;
        margin-bottom: 15px;
      }`
    ]
})
export class MenuComponent {
    @Input() curRoute: string;
    @Input() logged: boolean;
    @Input() collapse: boolean;
    @Input() mobile: boolean;
    @Output() logout = new EventEmitter();
}
