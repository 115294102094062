import {createAction, props} from '@ngrx/store';
import { APIErrorResponse } from 'src/app/common/http';
import { Profile } from 'src/app/profile/models/profile';


export const ConfirmSuccess = createAction(
    '[Confirm/API] Confirm Success'
);
export const ConfirmFailure = createAction(
    '[Confirm/API] Confirm Failure',
    props<{error:APIErrorResponse}>()
);
export const validationFailure = createAction(
    '[Confirm/API] Validation Failure',
    props<{error:APIErrorResponse | any}>()
);

export const getProfileSuccess = createAction(
    '[Confirm/Api] get Profile Success',
    props<{data:Profile}>()
);
export const getProfileFailure = createAction(
    '[Confirm/Api] get Profile Failure',
    props<{error:string}>()
);
export const updateProfileSuccess = createAction(
    '[Confirm/Api] update Profile Success',
    props<{data:Profile}>()
);
export const updateProfileFailure = createAction(
    '[Confirm/Api] update Profile Failure',
    props<{error:string}>()
);
export const CheckLinkSuccessUser = createAction(
    '[Confirm/API] CheckLink Success User',
    props<{param:string, status:number}>()
);
export const CheckLinkFailureUser = createAction(
    '[Confirm/API] CheckLink Failure User',
    props<{error: string}>()
);