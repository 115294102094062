import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ConfirmPageComponent} from './containers/confirm-page.component';
import {AuthGuard} from '../auth/services';

const routes: Routes = [
    {path: 'confirm/:accesToken', component: ConfirmPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConfirmRoutingModule {}
