import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Token } from 'src/app/auth/models';


@Component({
    selector: 'app-google',
    templateUrl: './google.component.html',
    styleUrls: ['./google.component.css']
})
export class GoogleComponent implements OnInit, OnDestroy {
    @Input() token: Token | null;

    constructor(public router:Router) { }

    ngOnInit(): void {
    }
    ngOnDestroy() {
  
    }
}
