import {trigger, animate, transition, style, state} from '@angular/animations';
export const FadeAnimation = trigger('fadeAnimation', [


    state('out', style({'opacity': '0' })),
    state('in', style({ 'opacity': '1' })),

    transition('out => in', [
        animate('400ms 50ms ease-in')
    ]),

    transition('in => out', [
        animate('400ms 50ms ease-in')
    ])
])
