import {createAction, props} from '@ngrx/store';
import {Token} from '../models/auth';

export const loginSuccess = createAction(
    '[Auth/API] Login Success',
    props<Token>()
);

export const loginFailure = createAction(
    '[Auth/API] Login Failure',
    props<{error: string}>()
);

export const changePasswordSuccess = createAction('[Auth/API] Change Password Success');

export const changePasswordFailure = createAction(
    '[Auth/API] Change Password Failure',
    props<{error: string}>()
);

export const refreshTokenSuccess = createAction(
    '[Auth/API] Refresh Token Success',
    props<Token>()
);

export const clientCredentialsTokenSuccess = createAction(
    '[Auth/API] Client Credentials Token Success',
    props<Token>()
);

