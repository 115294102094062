import {createAction, props} from '@ngrx/store';
import { Profile } from 'src/app/profile/models/profile';


export const confirm = createAction(
    '[Confirm Page] Confirm Data'
);
export const confirmAccount = createAction(
    '[Confirm Page] Confirm Data with Account',
    props<{account:string}>()
);
export const getProfile = createAction(
    '[Confirm Page] get Profile'
);
export const resetSended = createAction(
    '[Confirm Page] Reset Sended Data Confirm'
);
export const updateProfile = createAction(
    '[Confirm Page] Update Profile',
    props<{data:Profile}>()
);
export const setToken = createAction(
    '[Profile Page] Set Token',
    props<{accesToken:string}>()
);
export const CheckLinkUser = createAction(
    '[Profile Page] Check Current Link User'
);