import {Component} from '@angular/core';
import {Store, select} from '@ngrx/store';

@Component({
    template: `
      <app-faq>
      </app-faq>`
})
export class FaqPageComponent{
    constructor() {}
}
