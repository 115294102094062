<div class="container">
    <div *ngIf="sended && showSuccess; else confirmForm" class="row text-center my-5 py-5">
      <div class="col-12 pt-5">
        <p>Zlecenie zostało wysłane do realizacji.</p>
      </div>
      <div class="col-12">
        <a [routerLink]="[ '/' ]">Powrót na stronę główną ></a>
      </div>
    </div>
  <ng-template #confirmForm>
    <div class="row">
      <div class="col-lg-8 offset-lg-2 mb-4">
        <h4>W celu dokończenia zlecenia wypłaty monet uzupełnij dane do rozliczenia:</h4>
        <!-- "pierwsze wprowadzanie: {{firstTime}}" -->
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="form-check">
            <input #company class="form-check-input radio" type="checkbox" id="statement_b"
              [ngClass]="{'ng-invalid ng-touched': income == undefined && form.dirty}"
              (change)="changeIncome('company',$event, another)">
            <label class="form-check-label" for="statement_b">
              wartość nagrody będzie stanowiła dla mnie przychód z prowadzonej przeze mnie działalności
              gospodarczej
            </label>
          </div>
          <div class="form-check">
            <input #another class="form-check-input radio" type="checkbox" id="statement_a"
              [ngClass]="{'ng-invalid ng-touched': income == undefined && form.dirty}"
              (change)="changeIncome('another',$event, company)">
            <label class="form-check-label" for="statement_a">
              wartość nagrody będzie stanowiła dla mnie przychód z innych źródeł
            </label>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <input type="text" class="form-control" id="name" placeholder="Imię" formControlName="name" required>
            </div>
            <div class="form-group col-6">
              <input type="text" class="form-control" id="surname" placeholder="Nazwisko" formControlName="surname"
                required>
            </div>
          </div>
          <div class="form-row d-none">
            <div class="form-group col-6">
              <input type="tel" class="form-control" placeholder="Numer telefonu"
                prefix="+48" mask="000000000" formControlName="phone">
            </div>
            <div class="form-group col-6">
              <input type="email" class="form-control" placeholder="Adres e-mail" formControlName="email">
            </div>
          </div>
          <div #restForm [@slideInOut]="{value: income == 'another' ?'in':'out', params:{pixels:'600px', time:'200ms'}}" [ngClass]="{'pointer-none': income == 'company'}">
            <div class="form-row">
              <div class="form-group col-md-4 col-5">
                <input type="text" class="form-control" mask="00-000" placeholder="Kod pocztowy" formControlName="zipCode"
                  required>
              </div>
              <div class="form-group col-md-8 col-7">
                <input type="text" class="form-control" placeholder="Miejscowość" formControlName="place" required>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6 col-5">
                <input type="text" class="form-control" placeholder="Ulica" formControlName="street" required>
              </div>
              <div class="form-group col-md-3 col-4">
                <input type="text" class="form-control" placeholder="Nr domu" formControlName="houseNumber" required>
              </div>
              <div class="form-group col-md-3 col-3">
                <input type="text" class="form-control" placeholder="Lokal" formControlName="apartmentNumber" required>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <input type="text" class="form-control" placeholder="Gmina" formControlName="community" required>
              </div>
              <div class="form-group col-md-4">
                <input type="text" class="form-control" placeholder="Powiat" formControlName="district" required>
              </div>
              <div class="form-group col-md-4">
                <input type="text" class="form-control" placeholder="Poczta" formControlName="post" required>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <input type="text" class="form-control" placeholder="Województwo" formControlName="voivodeship"
                  [matAutocomplete]="autoVoivodeships" required>
                <mat-autocomplete #autoVoivodeships="matAutocomplete">
                  <mat-option *ngFor="let voivodeship of filteredVoivodeships | async" [value]="voivodeship">
                    {{voivodeship}}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div class="form-group col-md-6">
                <input type="text" class="form-control" placeholder="Urząd Skarbowy" formControlName="taxOffice"
                  [matAutocomplete]="autoTaxOffices" required>
                <mat-autocomplete #autoTaxOffices="matAutocomplete">
                  <mat-option *ngFor="let taxOffice of filteredTaxOffices | async" [value]="taxOffice">
                    {{taxOffice}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input checkbox" type="checkbox" id="statement_c" formControlName="billingStatement"
              [value]="false" required>
            <label class="form-check-label" for="statement_c">
              Zobowiązuje się do niezwłocznej aktualizacji powyższych danych na koncie uczestnika programu
              ERGO Hestia DILER GO!
              jeśli ulegną zmianie. </label>
          </div>
          <div class="form-check d-none">
            <input class="form-check-input checkbox" type="checkbox" id="statement_d" formControlName="statement"
              [value]="false">
            <label class="form-check-label" for="statement_d">Wyrażam zgodę na przetwarzanie moich danych osobowych
              przez Sopockie Towarzystwo Ubezpieczeń ERGO Hestia S.A. oraz Sopockie Towarzystwo Ubezpieczeń na Życie
              ERGO Hestia S.A. w celu otrzymywania informacji marketingowych z&nbsp;wykorzystaniem środków
              porozumiewania się na odległość (telefon, e-mail, indywidualne Konto Uczestnika udostępnione
              w&nbsp;Aplikacji Programu), przy użyciu podanych przeze mnie danych kontaktowych.</label>
          </div>
                      <!-- TODO: ZROBIĆ pobranie z api czy KASA CZY NAGRODA -->
          <p class="section-description">Numer konta bankowego</p>
            <div class="form-row">
              <div class="form-group col-12">
                <input type="text" class="form-control" placeholder="Numer konta bankowego"
                  mask="00 0000 0000 0000 0000 0000 0000" formControlName="account" required>
              </div>
            </div>
            
          <p class="section-description">Adres do wysyłki nagród</p>
          <div class="form-row">
            <div class="form-group col-md-4 col-5">
              <input type="text" class="form-control" mask="00-000" placeholder="Kod pocztowy"
                formControlName="deliveryZipCode" required>
            </div>
            <div class="form-group col-md-8 col-7">
              <input type="text" class="form-control" placeholder="Miejscowość" formControlName="deliveryPlace" required>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6 col-5">
              <input type="text" class="form-control" placeholder="Ulica" formControlName="deliveryStreet" required>
            </div>
            <div class="form-group col-md-3 col-4">
              <input type="text" class="form-control" placeholder="Nr domu" formControlName="deliveryHouseNumber" required>
            </div>
            <div class="form-group col-md-3 col-3">
              <input type="text" class="form-control" placeholder="Lokal" formControlName="deliveryApartmentNumber"
                required>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-4">
              <span class="form-error" *ngIf="(form.invalid && form.dirty) || formInvalid">Wymagane pola nie zostały uzupełnione</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-6">
              <button type="button" (click)="toggleEdit()" class="btn btn-secondary btn-block"
              [innerText]="edit?'Anuluj':'Edytuj'">Edytuj</button>
            </div>
            <div class="col-md-6 col-6">
              <button type="button" (click)="submit()" class="btn btn-block" [ngClass]="{'btn-dark': edit, 'btn-primary':!edit}" 
              [innerText]="edit?'Zapisz':'Potwierdź'">Potwierdź</button>
            </div>
          </div>
        </form>
        <div class="row mt-3" *ngIf="showDataUpdate">
          <div class="col-12">
            <p style="color:#28a745">Twoje dane zostały zaktualizowane</p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>