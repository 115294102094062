import {Component} from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styles:[`
    .list-group{
        padding-left: 20px;
    }
    .minus{
        list-style: "\\2212";
    }
    .minus li{
        padding-left:10px;
      }
      ol{
          margin-left:0;
      }
      ol li {
        padding-bottom: 0px;
    }
      .point-list {
        list-style-type: disc;
      }
    `]
})
export class FaqComponent {

}
