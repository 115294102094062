import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginPageComponent} from './containers/login-page.component';
import {AuthGuard} from './services/auth-guard.service';

const routes: Routes = [
    {path: 'login', component: LoginPageComponent, canActivate: [AuthGuard], data: {logged: true}},
    {path: 'login/:tokenId', component: LoginPageComponent, canActivate: [AuthGuard], data: {logged: true}}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
