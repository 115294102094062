import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { APIErrorResponse } from 'src/app/common/http';
import { Contact } from '../models';
import { Utils } from 'src/app/common/utils';
import { apiUrl } from 'src/app/common/variables';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    constructor(private http: HttpClient) {}

    public sendMessage(msg:any): Observable<boolean> {
        // console.log(msg);
        
        const params = new HttpParams()
        .set('title', msg.title)
        .set('message', msg.message);
        
        return this.http.post<boolean>(apiUrl + 'contact/send', params)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError(error.error.type);
            })
        );
    }
    
}