import {Component} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Credentials} from '../models/auth';
import * as fromAuth from '../reducers';
import {LoginPageActions} from '../actions';

@Component({
    template: `
      <app-login-form
        (submitted)="onSubmit($event)"
        (cleanFirst)="onCleanFirst()"
        [first]="first$ | async"
        [error]="error$ | async">
      </app-login-form>`
})
export class LoginPageComponent{
    error$ = this.store.pipe(select(fromAuth.selectLoginPageError));
    first$ = this.store.pipe(select(fromAuth.selectFirst));

    constructor(private store: Store<fromAuth.State>) {}

    onSubmit(credentials: Credentials) {
        this.store.dispatch(LoginPageActions.login(credentials));
    }
    onCleanFirst(){
      this.store.dispatch(LoginPageActions.cleanFirst());
    }
}
