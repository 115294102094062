import {NgModule} from '@angular/core';
import {MainRoutingModule} from './main-routing.module';
import {MainPageComponent} from './containers';
import {MainComponent} from './components';

export const COMPONENTS = [
    MainPageComponent,
    MainComponent,
];

@NgModule({
    imports: [
        MainRoutingModule
    ],
    declarations: COMPONENTS,
    providers: [
    ]
})
export class MainModule {
}