import {Component, OnDestroy} from '@angular/core';
import {Store, select} from '@ngrx/store';
import * as fromContact from '../reducers';
import { ContactPageActions } from '../actions';
import { Contact } from '../models';

@Component({
    template: `
      <app-contact
        [sended]="sended$ | async"
        [error]="error$ | async"
        (submitted)="onSubmit($event)">
      </app-contact>`
})
export class ContactPageComponent implements OnDestroy{
    sended$ = this.store.pipe(select(fromContact.selectSended));
    error$ = this.store.pipe(select(fromContact.selectError));
    constructor(private store: Store<fromContact.State>) {}

    
    onSubmit(msg: any) {
      this.store.dispatch(ContactPageActions.sendMessage({msg:{message:msg.message, title:msg.title}}));
    }
    ngOnDestroy(): void {
      this.store.dispatch(ContactPageActions.resetSended());
    }
}
