import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
@Component({
    selector: 'app-withdrawal',
    templateUrl: './withdrawal.component.html',
    styles: [`
`]
})
export class WithdrawalComponent {
    @Input() error: string | null;

    // ngOnChanges(changes: SimpleChanges): void {
    //   let simpleChange = changes['profile'];
    //   if (simpleChange && !simpleChange.firstChange && this.error) {

    //   }
    // }
}
