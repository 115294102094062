import {createAction, props} from '@ngrx/store';
import { Verification} from '../models/register';

export const verification = createAction(
    '[Register Page] Check if User Verification',
    props<{verification:Verification}>()
);
export const rules = createAction(
    '[Register Page] insert Rules'
);
export const confirmUser = createAction(
    '[Register Page] confirm User',
    props<{accesToken:string}>()
);
export const setPassword = createAction(
    '[Register Page] set Password',
    props<{password:string, confirmPassword:string}>()
);
