import {createAction, props} from '@ngrx/store';
import { Contact } from '../models';

export const sendMessage = createAction(
    '[Contact Page] Send Message',
    props<{msg:Contact}>()
);
export const setSended = createAction(
    '[Contact Page] Set Sended'
);
export const resetSended = createAction(
    '[Contact Page] reset Sended'
);