import {NgModule} from '@angular/core';
import {ProfileRoutingModule} from './profile-routing.module';
import {ProfilePageComponent} from './containers';
import {ProfileComponent} from './components';
import {EffectsModule} from '@ngrx/effects';
import * as fromProfile from './reducers';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {ProfileEffects} from './effects';
import {StoreModule} from '@ngrx/store';
import {ReactiveFormsModule} from '@angular/forms';
import { NgxMaskModule} from 'ngx-mask'
import { CommonModule } from '@angular/common';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


export const COMPONENTS = [
    ProfilePageComponent,
    ProfileComponent,
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        ProfileRoutingModule,
        MatAutocompleteModule,
        TabsModule.forRoot(),
        NgxMaskModule.forRoot(),
        StoreModule.forFeature(fromProfile.profileFeatureKey, fromProfile.reducers),
        EffectsModule.forFeature([ProfileEffects])
    ],
    declarations: COMPONENTS,
    providers: [
    ]
})
export class ProfileModule {
}