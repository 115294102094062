import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromWithdrawal from '../reducers';
import {Store, select} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {WithdrawalService} from '../service';
import { WithdrawalPageActions, WithdrawalApiActions } from '../actions';
import { withLatestFrom, map, exhaustMap, catchError, tap } from 'rxjs/operators';
import { APIErrorResponse } from 'src/app/common/http';
import { of } from 'rxjs';

@Injectable()
export class WithdrawalEffects {
    checkLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WithdrawalPageActions.CheckLink),
            withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUrlToken))),
            map(action=>action[1]),
            exhaustMap((token) =>
                this.withdrawalService.checkLink(token).pipe(
                    map((res: boolean) =>{
                        if(res)
                            return WithdrawalApiActions.CheckLinkSuccess({param:token})
                        else
                            return WithdrawalApiActions.CheckLinkFailure({error:'Podany link jest nieaktywny'})
                        }
                    ),
                    catchError((error: string) =>
                        of(WithdrawalApiActions.CheckLinkFailure({ error }))
                    )
                )
            )
        )
    );
    checkLinkSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WithdrawalApiActions.CheckLinkSuccess),
            map(action=>action.param),
            tap((param) => {
                this.router.navigate(['/confirm', param]);
            })
        ),
        {dispatch: false}
    );

    constructor(
        private store: Store<fromWithdrawal.State>,
        private actions$: Actions,
        private withdrawalService: WithdrawalService,
        private router: Router
    ) {}
}
