import {createAction, props} from '@ngrx/store';
import { APIErrorResponse } from 'src/app/common/http';

export const CheckLinkSuccess = createAction(
    '[Withdrawal/API] CheckLink Success',
    props<{param:string}>()
);
export const CheckLinkFailure = createAction(
    '[Withdrawal/API] CheckLink Failure',
    props<{error: string}>()
);