import { ConfirmPageActions, ConfirmApiActions } from '../actions';
import { createReducer, on } from '@ngrx/store';
import { APIErrorResponse } from 'src/app/common/http';
import { Profile } from 'src/app/profile/models/profile';

export const profilePageFeatureKey = 'profilePage';

export interface State {
    profile:Profile | null,
    validationError: APIErrorResponse | any | null;
    error:string | null
    sended: boolean | null;
    status:number | null;
}

export const initialState: State = {
    validationError: null,
    profile:null,
    error:null,
    sended:null,
    status: null
};

export const reducer = createReducer(
    initialState,
    on(
        ConfirmApiActions.getProfileSuccess,
        (state, action) => ({ ...state, profile: action.data, sended: false })),
    on(ConfirmApiActions.validationFailure,
        (state, payload) => ({...state, validationError: payload.error})),
    on(ConfirmPageActions.resetSended,
        (state)=> ({...state, sended:null})),
    on(ConfirmPageActions.updateProfile,
        (state)=>({...state, sended:false})),
    on(
        ConfirmApiActions.updateProfileSuccess,
        (state, action) => ({ ...state, sended: true })),
    on(
        ConfirmPageActions.confirm,
        state => ({ ...state, sended: false })),
    on(
        ConfirmApiActions.ConfirmSuccess,
        state => ({ ...state, sended: true })),
    on(ConfirmPageActions.CheckLinkUser,
        state=>({...state, status: null})),
    on(ConfirmApiActions.CheckLinkSuccessUser,
        (state, status)=>({...state, status: status.status}))
);

export const selectValidationError = (state: State) => state.validationError;
export const selectError = (state: State) => state.error;
export const selectProfile = (state: State) => state.profile;
export const selectSended = (state: State) => state.sended;
export const selectStatus = (state: State) => state.status;