import {createSelector, createFeatureSelector, combineReducers, Action} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromWelcomePage from './welcome-page.reducer';

export const welcomeFeatureKey = 'welcome';

export interface WelcomeState {
    [fromWelcomePage.welcomePageFeatureKey]: fromWelcomePage.State;
}

export interface State extends fromRoot.State {
    [welcomeFeatureKey]: WelcomeState;
}

export function reducers(state: WelcomeState | undefined, action: Action) {
    return combineReducers({
        [fromWelcomePage.welcomePageFeatureKey]: fromWelcomePage.reducer,
    })(state, action);
}

export const selectWelcomeState = createFeatureSelector<State, WelcomeState>(welcomeFeatureKey);

export const selectLoginPageState = createSelector(
    selectWelcomeState,
    (state: WelcomeState) => state.welcomePage
);
export const selectLoginPageError = createSelector(
    selectLoginPageState,
    fromWelcomePage.selectError
);
