import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef, OnInit, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { Utils } from 'src/app/common/utils';
import { Profile, ChangePassword } from '../models/profile';
import { FadeAnimation } from '../../animations/fade.animation';
import { SlideInOutAnimation } from 'src/app/animations/slideinout.animations';
import {urzedy} from '../../common/urzedy';
import {voivodeships} from '../../common/voivodeships';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  animations: [FadeAnimation, SlideInOutAnimation],
  styles:[`
  .checkbox:disabled ~ .form-check-label, .checkbox[disabled] ~ .form-check-label{
    color: inherit;
  }
  .pointer-none{
    pointer-events:none;
  }
  `]
})
export class ProfileComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() profile: Profile | null;
  @Input() error: any | null;
  @Input() validationError: any | null;
  @Input() passSended: boolean | null;
  @Input() sended: boolean | null;

  @Output() submitted = new EventEmitter<Profile>();
  @Output() submittedPassowrd = new EventEmitter<ChangePassword>();
  @Output() resetSended = new EventEmitter<any>();
  @Output() epit = new EventEmitter<any>();

  @ViewChild('tabset') tabset: TabsetComponent;
  @ViewChild('another') anotherCheck!: ElementRef;
  @ViewChild('company') companyCheck!: ElementRef;

  constructor(private formBuilder: FormBuilder) { }
  form: FormGroup;
  taxOffices = urzedy;
  voivodeships = voivodeships;
  filteredVoivodeships: Observable<string[]>;
  filteredTaxOffices: Observable<string[]>;
  showDataUpdate = false;
  edit = false;

  formCompany = {
    name: new FormControl(''),
    surname: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    account: new FormControl('', [Validators.minLength(26), Validators.maxLength(26)]),
    deliveryZipCode: new FormControl('', Validators.minLength(5)),
    deliveryPlace: new FormControl(''),
    deliveryStreet: new FormControl(''),
    deliveryHouseNumber: new FormControl(''),
    deliveryApartmentNumber: new FormControl('')

  };
  formAnother={
    name: this.formCompany.name,
    surname: this.formCompany.surname,
    phone: this.formCompany.phone,
    email: this.formCompany.email,
    zipCode: new FormControl('', Validators.minLength(5)),
    place: new FormControl(''),
    street: new FormControl(''),
    houseNumber: new FormControl(''),
    apartmentNumber: new FormControl(''),
    community: new FormControl(''),
    district: new FormControl(''),
    post: new FormControl(''),
    voivodeship: new FormControl('', Utils.autocompleteStringValidator(this.voivodeships)),
    taxOffice: new FormControl('', Utils.autocompleteStringValidator(this.taxOffices)),
    account: this.formCompany.account,
    deliveryZipCode: this.formCompany.deliveryZipCode,
    deliveryPlace: this.formCompany.deliveryPlace,
    deliveryStreet: this.formCompany.deliveryStreet,
    deliveryHouseNumber: this.formCompany.deliveryHouseNumber,
    deliveryApartmentNumber: this.formCompany.deliveryApartmentNumber
  };
  agreements = {
    statement: new FormControl(false),
    billingStatement: new FormControl(''),
    pitStatement: new FormControl(''),
  };
  income:'another' | 'company';
  password: FormGroup = new FormGroup({
    oldPassword: new FormControl(''),
    newPassword: new FormControl(''),
    confirmPassword: new FormControl('')
  });

  ngOnInit(): void {
    this.updateForm();
    this.validationError = null;
    this.error = null;

    this.filteredVoivodeships = this.form.get('voivodeship').valueChanges.pipe(
      startWith(''),
      map(value => this._filterVoivodeships(value))
    );
    this.filteredTaxOffices = this.form.get('taxOffice').valueChanges.pipe(
      startWith(''),
      map(value => this._filterTaxOffices(value))
    );
  }
  private _filterVoivodeships(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.voivodeships.filter(voivodeship => this._normalizeValue(voivodeship).includes(filterValue));
  }
  private _filterTaxOffices(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.taxOffices.filter(taxOffices => this._normalizeValue(taxOffices).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  ngAfterViewInit(): void {
    this.income;

    this.updateForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    let simpleChange = changes['profile'];
    let simpleChangePass = changes['passSended'];
    let simpleChangeSended = changes['sended'];
    let simpleChangeError = changes['error'];
    if (simpleChange && !simpleChange.firstChange && this.profile) {
      this.setProfile();
    }
    if (simpleChangePass && !simpleChangePass.firstChange && this.passSended) {
      this.password.disable();
    }
    if (simpleChangeSended && !simpleChangeSended.firstChange) {
      if(this.sended){
        this.edit = false;
        this.showDataUpdate = true;
        this.form.disable();
        this.companyCheck.nativeElement.disabled = true;
        this.anotherCheck.nativeElement.disabled = true;
        setTimeout(() => {
          this.showDataUpdate = false;
          this.resetSended.emit();
        }, 2000);
      }else{
        this.showDataUpdate = false;
      }
    }
    if (simpleChangeError && !simpleChangeError.firstChange) {
      setTimeout(() => {
        this.resetSended.emit();
      }, 2000);
    }
  }
  setProfile(){
    for (const [key, value] of Object.entries(this.profile)) {
      if (key != '_id' && this.form.controls[key]) {
        this.form.controls[key].setValue(value);
        if(key=='email' || key=='name' || key=='surname' || key=='billingStatement')
          this.form.controls[key].disable();
      }
    }
    if(this.profile.billingStatement){
      if(this.profile.zipCode){
        this.income = 'another'
        this.anotherCheck.nativeElement.checked = true;
        this.companyCheck.nativeElement.checked = false;
      }else{
        this.income = 'company'
        this.companyCheck.nativeElement.checked = true;
        this.anotherCheck.nativeElement.checked = false;
      }
    }
    this.updateForm();
  }
  updateForm(){
    if(this.income == 'company'){
        this.form = this.formBuilder.group({...this.formCompany,...this.agreements});
        // console.log("formCompany, agree2");
    }else{
        this.form = this.formBuilder.group({...this.formAnother,...this.agreements});
    }
    if(this.companyCheck && this.anotherCheck){
      if(this.edit){
        this.companyCheck.nativeElement.disabled = false;
        this.anotherCheck.nativeElement.disabled = false;
      }else{
        this.companyCheck.nativeElement.disabled = true;
        this.anotherCheck.nativeElement.disabled = true;
      }
    }
    if(this.edit){
      this.form.enable();
    }else{
      setTimeout(() => {
        this.form.disable();
      }, 10);

    }
    this.form.get('name').disable();
    this.form.get('surname').disable();
    this.form.get('email').disable();
    if(this.form.get('billingStatement').value) this.form.get('billingStatement').disable();
    this.form.updateValueAndValidity();
  }
  toggleEdit(){
    this.edit = !this.edit;
    this.updateForm();
    if(this.edit === false){
      this.income = undefined;
      this.companyCheck.nativeElement.checked = false;
      this.anotherCheck.nativeElement.checked = false;
      this.setProfile();
    }
  }
  changeIncome(i:'another' | 'company', event:any, secondCheck:any){
    if(this.edit){
      secondCheck.checked = false;
      if(this.income != i)
        this.income = i;
      else
        event.target.checked = true;

      if(this.form.get("zipCode") == undefined)
        setTimeout(() => {
          this.updateForm();
        }, 100);
    }
    this.updateForm();
  }
  submit() {
    // // console.log(this.form.value);
    if(this.edit){
      this.form.markAsDirty();
      if (this.form.valid && this.income) {
        let data:Profile = {...this.form.getRawValue(), income: this.income =='company'?false:true}

        this.submitted.emit(data);
        // console.log(data);
      }
      else {
        Utils.validateFormFields(this.form);
      }
    }else{
      this.toggleEdit();
    }
  }
  submitChangePassword() {
    if (this.password.valid) {
      this.submittedPassowrd.emit(this.password.value);
    }
    else {
      Utils.validateFormFields(this.password);
    }
  }

  epitDownload(){
    this.epit.emit();
  }
}
