import {createFeatureSelector, createSelector, Action, combineReducers} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromResetPassword from './reset-password.reducer';

export const resetPasswordFeatureKey = 'resetPassword';

export interface ResetPasswordState {
    [fromResetPassword.resetPasswordPageFeatureKey]: fromResetPassword.State;
}

export interface State extends fromRoot.State {
    [resetPasswordFeatureKey]: ResetPasswordState;
}

export function reducers(state: ResetPasswordState | undefined, action: Action) {
    return combineReducers({
        [fromResetPassword.resetPasswordPageFeatureKey]: fromResetPassword.reducer
    })(state, action);
}

export const selectResetPasswordState = createFeatureSelector<State, ResetPasswordState>(resetPasswordFeatureKey);

export const selectResetPasswordPageState = createSelector(
    selectResetPasswordState,
    state => state.resetPasswordPage
);

export const selectResetPasswordPageValidationError = createSelector(
    selectResetPasswordPageState,
    fromResetPassword.selectValidationError
);
export const selectResetPasswordError = createSelector(
    selectResetPasswordPageState,
    fromResetPassword.selectError
);
export const selectUser = createSelector(
    selectResetPasswordPageState,
    fromResetPassword.selectUser
);
export const selectToken = createSelector(
    selectResetPasswordPageState,
    fromResetPassword.selectToken
);
export const selectSended = createSelector(
    selectResetPasswordPageState,
    fromResetPassword.selectSended
);