import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import {Token} from '../../auth/models/auth';

@Injectable({
    providedIn: 'root',
})
export class MainGuard implements CanActivate {
    constructor(private store: Store<fromAuth.State>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(fromAuth.selectToken),
            map((token: Token) => {
                if (token && token.refresh_token) {
                    this.router.navigate(['/welcome']);
                    return false;
                }
                return true;
            }),
            take(1)
        );
    }
}
