import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Credentials } from '../models/auth';
import { Utils } from '../../common/utils';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-login-form',
  template: `

      <div class="col bg-white inner-content">

      <h3 *ngIf="first"><span>Hasło zostało ustalone poprawnie</span></h3>
      <p *ngIf="first" class="section-description">Możesz teraz zalogować się do swojego konta</p>

        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 pt-5">

            <form [formGroup]="form" (ngSubmit)="submit()" [ngClass]="{'pt-100-lg': !first}">

              <div class="form-group">
                <input type="email" class="form-control" [ngClass]="{'is-invalid': error}" placeholder="Twój adres e-mail" formControlName="email" required email>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" [ngClass]="{'is-invalid': error}" placeholder="Twoje hasło" formControlName="password" required>
              </div>
              <div class="row p-0">
                <div class="col-12 mb-4">
                  <span class="form-error" *ngIf="error">Podano zły login lub hasło</span>
                </div>
              </div>

              <p class="reset text-center" [routerLink]="[ '/reset-password' ]">zresetuj hasło</p>


              <div class="row">
                <button type="submit" class="btn btn-primary btn-block login-button">Zaloguj</button>
              </div>

            </form>

          </div>
        </div>

      </div>`,
  styles: [`.reset{
      font-size: 14px;
      cursor: pointer;
    }`]
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Input() error: any | null;
  @Input() first: boolean | null;
  @Output() submitted = new EventEmitter<Credentials>();
  @Output() cleanFirst = new EventEmitter<any>();

  form: FormGroup = new FormGroup({
    email: new FormControl('', RxwebValidators.email()),
    password: new FormControl('')
  });

  ngOnInit(): void {
    this.error = null;
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
    else {
      Utils.validateFormFields(this.form);
    }
  }
  ngOnDestroy(): void {
    if (this.first) {
      this.cleanFirst.emit();
    }
  }
}
