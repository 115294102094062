import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


const routes: Routes = [
    {
        path: 'register',
        loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
    },
    {path: '', redirectTo: '', pathMatch: 'full'},
    {path: '**', redirectTo: '', pathMatch: 'full'}];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],//
    exports: [RouterModule]
})
export class AppRoutingModule {}
