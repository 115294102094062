import {ResetPasswordApiActions, ResetPasswordPageActions} from '../actions';
import {createReducer, on} from '@ngrx/store';
import {Verification} from '../models/reset-password';
import {APIErrorResponse} from 'src/app/common/http';

export const resetPasswordPageFeatureKey = 'resetPasswordPage';

export interface State {
    validationError: APIErrorResponse | null;
    error: any | null;
    user: Verification | null;
    accesToken:string;
    sended:boolean;
}

export const initialState: State = {
    validationError: null,
    error: null,
    user: null,
    accesToken:null,
    sended:false
};

export const reducer = createReducer(
    initialState,
    on(
        ResetPasswordApiActions.validationFailure,
        ResetPasswordApiActions.setPasswordFailure,
        (state, payload) => ({...state, validationError: payload.error})),
    on(
        ResetPasswordApiActions.sendLinkSuccess,
        (state, payload) => ({...state, error: null, sended:true})),
    on(
        ResetPasswordApiActions.sendLinkFailure,
        (state, err) => ({...state, error: err.error})),
    on(
        ResetPasswordApiActions.sendResetLinkSuccess,
        (state, payload) => ({...state, error: null, sended:true})),
    on(
        ResetPasswordApiActions.sendResetLinkFailure,
        (state, err) => ({...state, error: err.error})),
    on(
        ResetPasswordPageActions.resetSended,
        (state, payload) => ({...state, error: null, sended:false})),
    on(
        ResetPasswordApiActions.confirmUserSuccess,
        (state, payload) => ({...state, error: null, user: {...state.user, email:payload.email, accesToken:payload.accesToken}}))
);

export const selectValidationError = (state: State) => state.validationError;
export const selectError = (state: State) => state.error;
export const selectUser = (state: State) => state.user;
export const selectToken = (state: State) => state.accesToken;
export const selectSended = (state: State) => state.sended;
