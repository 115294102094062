import {NgModule} from '@angular/core';
import {ConfirmRoutingModule} from './confirm-routing.module';
import {ConfirmPageComponent} from './containers';
import {ConfirmComponent} from './components';
import {EffectsModule} from '@ngrx/effects';
import * as fromConfirm from './reducers';
import {ConfirmEffects} from './effects';
import {StoreModule} from '@ngrx/store';
import {ReactiveFormsModule} from '@angular/forms';
import { NgxMaskModule} from 'ngx-mask'
import { CommonModule } from '@angular/common';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

export const COMPONENTS = [
    ConfirmPageComponent,
    ConfirmComponent,
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        ConfirmRoutingModule,
        MatAutocompleteModule,
        NgxMaskModule.forRoot(),
        StoreModule.forFeature(fromConfirm.profileFeatureKey, fromConfirm.reducers),
        EffectsModule.forFeature([ConfirmEffects])
    ],
    declarations: COMPONENTS,
    providers: [
    ]
})
export class ConfirmModule {
}