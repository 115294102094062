import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VerificationPageComponent, AfterRegisterPageComponent} from './containers';
import {PasswordPageComponent} from './containers';
import { AuthGuard } from '../auth/services';

const routes: Routes = [
    {path: '', redirectTo: 'verification', pathMatch: 'full'},
    {path: 'verification', component: VerificationPageComponent, canActivate: [AuthGuard], data: {logged: true}},
    {path: 'password/:accesToken', component: PasswordPageComponent, canActivate: [AuthGuard], data: {logged: true}},
    {path: 'complete', component: AfterRegisterPageComponent, canActivate: [AuthGuard], data: {logged: true}}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RegisterRoutingModule {}