import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { GoogleRoutingModule } from './google-routing.module';
import { GoogleComponent } from './components';
import { GooglePageComponent } from './containers';


export const COMPONENTS = [
    GoogleComponent,
    GooglePageComponent
];

@NgModule ({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    GoogleRoutingModule,
  ],
  declarations: COMPONENTS,
  providers: []
})
export class GoogleModule {}