import {createAction, props} from '@ngrx/store';
import {Token} from '../models/auth';

export const refreshToken = createAction(
    '[Auth] Refresh Token',
    props<Token>()
);

export const clientCredentialsToken = createAction('[Auth] Client Credentials Token');

export const logout = createAction('[Auth] Logout');

export const loginRedirect = createAction('[Auth] Login Redirect');

export const loginRedirectParam = createAction(
    '[Auth] Login Redirect with Param',
    props<{param:string}>()
);

export const redirectLoginSuccess = createAction('[Auth] Redirect Login Success');
