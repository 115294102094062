import { select, Store } from '@ngrx/store';
import * as fromResetPassword from '../reducers';
import { ResetPasswordPageActions } from '../actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    template: `
    <app-password-form
      (submitted)="onSubmit($event)"
      [validationError]="validationError$ | async"
      [user]="user$ | async"
      [error]="error$ | async">
    </app-password-form>`
})
export class ResetPasswordPageComponent implements OnInit, OnDestroy {
    validationError$ = this.store.pipe(select(fromResetPassword.selectResetPasswordPageValidationError));
    user$ = this.store.pipe(select(fromResetPassword.selectUser));
    error$ = this.store.pipe(select(fromResetPassword.selectResetPasswordError));
    private sub: Subscription;
    private accesToken: string;
    constructor(protected store: Store<fromResetPassword.State>, private route: ActivatedRoute) {
    }
    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.accesToken = params['accesToken'];
            this.store.dispatch(ResetPasswordPageActions.confirmUser({ accesToken: this.accesToken }));
        });
    }
    onSubmit(passwords: any) {
        this.store.dispatch(ResetPasswordPageActions.setPassword({ password:passwords.password, confirmPassword:passwords.confirmPassword }));
    }
    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
