import {createAction, props} from '@ngrx/store';
import { Verification} from '../models/reset-password';

export const confirmUser = createAction(
    '[ResetPassword Page] confirm User',
    props<{accesToken:string}>()
);
export const setPassword = createAction(
    '[ResetPassword Page] set Password',
    props<{password:string, confirmPassword:string}>()
);
export const sendLink = createAction(
    '[ResetPassword Page] Send Link',
    props<{email:string}>()
);
export const sendResetLink = createAction(
    '[ResetPassword Page] Send Reset Link',
    props<{email:string}>()
);
export const setSended = createAction(
    '[ResetPassword Page] Set sended'
);
export const resetSended = createAction(
    '[ResetPassword Page] Reset sended'
);
