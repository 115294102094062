import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { APIErrorResponse } from 'src/app/common/http';
import { apiUrl } from 'src/app/common/variables';

@Injectable({
    providedIn: 'root'
})
export class ConfirmService {
    constructor(private http: HttpClient) { }

    public confirm(token:string): Observable<boolean> {
        const params = new HttpParams()
        .set('token', token);
        return this.http.post<boolean>(apiUrl + 'coins/withdrawal-confirm', params)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError(error.error.type);
            })
        );
    }
    public confirmAccount(token:string, account:string): Observable<boolean> {
        const params = new HttpParams()
        .set('account', account)
        .set('token', token);
        return this.http.post<boolean>(apiUrl + 'coins/withdrawal-confirm', params)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError(error.error.type);
            })
        );
    }
    public checkLinkUser(token:string): Observable<boolean | number> {
        const params = new HttpParams()
        .set('token', token);
        
        return this.http.post<boolean | number>(apiUrl + 'coins/verification-link-user', params)
        .pipe(
            map((res: boolean | number) => {
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError((error.error as any).error_description);
            })
        );
    }
}
