import {Component, OnInit, Renderer2, OnDestroy} from '@angular/core';
import {AppStore,GooglePlay} from '../../common/variables'

@Component({
    selector: 'app-main',
    templateUrl:'./main.component.html'
})
export class MainComponent implements OnInit, OnDestroy{
    AppStore = AppStore;
    GooglePlay = GooglePlay;
    constructor(private renderer: Renderer2){
    }

    ngOnInit(): void {
        this.renderer.addClass(document.body, 'homescreen');
    }
    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'homescreen');
    }
}
