import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import { SlideInOutAnimation } from '../../animations/slideinout.animations';

@Component({
    selector: 'app-topnav',
    template: `
    <div class="navbar-collapse" id="navbarResponsive" [ngClass]="{'mt-5': ismain, 'mx-auto': ismain}" [@slideInOut]="{value:collapse ?'in':'out', params:{pixels:'98px', time:'200ms'}}">
        <div class="text-center" [ngClass]="{'ml-auto': !ismain}">
            <ul #menu1 class="navbar-nav pb-2" *ngIf="!logged">
                <li class="nav-item outline" [ngClass]="{'active': curRoute === 'register' }">
                    <a class="nav-link" routerLink="register">Rejestracja</a>
                </li>
                <li class="nav-item outline" [ngClass]="{'active': curRoute === 'login' || (mobile && curRoute === '')}">
                    <a class="nav-link" routerLink="login">Zaloguj się</a>
                </li>
            </ul>
            <span *ngIf="!logged" class="resend-link" [routerLink]="[ '/send-link' ]">Wyślij ponownie link aktywacyjny</span>
            <div class="toolbox" *ngIf="logged && !mobile">
                <button type="button" class="btn btn-primary btn-epit btn-rounded mr-0 mr-lg-5 mt-5 mt-lg-0" *ngIf="epitAvailable" (click)="epitDownload.emit()">
                  <!-- <span class="epit-btn-large-screen d-none d-xl-block">Pobierz swój PIT-11 za nagrody zdobyte w Diler GO! w 2020 r.</span> -->
                  Pobierz PIT za {{ currentYear }} r.
                </button>
                <button type="button" class="logout" (click)="logout.emit()">Wyloguj się</button>
            </div>
        </div>
    </div>`,
    animations:[SlideInOutAnimation]
})
export class TopNavComponent {
    @HostBinding('class.w-100') get checkMain() { return !this.ismain; }
    @Input() curRoute: string;
    @Input() logged: boolean;
    @Input() collapse: boolean;
    @Input() mobile: boolean;
    @Input() ismain: boolean;
    @Input() epitAvailable: boolean;
    @Output() logout = new EventEmitter();
    @Output() epitDownload = new EventEmitter();

    currentYear: number;

    constructor() {
        this.currentYear = new Date().getFullYear() - 1;
    }
}
