import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {WelcomeRoutingModule} from './welcome-routing.module';
import {WelcomeEffects} from './effects';
import * as fromWelcome from './reducers';
import {WelcomePageComponent} from './containers';
import {WelcomeComponent} from './components';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
  };
export const COMPONENTS = [
    WelcomeComponent,
    WelcomePageComponent
];

@NgModule({
    imports: [
        CommonModule,
        SwiperModule,
        WelcomeRoutingModule,
        StoreModule.forFeature(fromWelcome.welcomeFeatureKey, fromWelcome.reducers),
        EffectsModule.forFeature([WelcomeEffects])
    ],
    declarations: COMPONENTS,
    providers: [
      {
        provide: SWIPER_CONFIG,
        useValue: DEFAULT_SWIPER_CONFIG
      }
    ]
})
export class WelcomeModule {}
