import {Component, OnDestroy} from '@angular/core';
import {Store, select} from '@ngrx/store';
import * as fromProfile from '../reducers';
import { Profile, ChangePassword } from '../models/profile';
import { ProfilePageActions } from '../actions';
import { ActivatedRoute } from '@angular/router';

@Component({
    template: `
      <app-profile
        [error]="error$ | async"
        [profile]="profile$ | async"
        [passSended]="passSended$ | async"
        [sended]="sended$ | async"
        [validationError]="validationError$ | async"
        (submitted)="onProfileSubmitted($event)"
        (submittedPassowrd)="onChangePassword($event)"
        (resetSended)="reset()"
        (epit)="onEpit()"
        >
      </app-profile>`
})
export class ProfilePageComponent implements OnDestroy{
    error$ = this.store.pipe(select(fromProfile.selectError));
    profile$ = this.store.pipe(select(fromProfile.selectProfile));
    validationError$ = this.store.pipe(select(fromProfile.selectRegisterPageValidationError));
    passSended$ = this.store.pipe(select(fromProfile.selectPassSended));
    sended$ = this.store.pipe(select(fromProfile.selectSended));

    constructor(private store: Store<fromProfile.State>, private route: ActivatedRoute) {
        this.store.dispatch(ProfilePageActions.getProfile());
    }

    onProfileSubmitted(data:Profile){
        // if(data.statement === null||data.statement.toString() === ''){
        //     delete data.statement;
        // }
        this.store.dispatch(ProfilePageActions.updateProfile({data}));
    }
    onChangePassword(credentials:ChangePassword){
        this.store.dispatch(ProfilePageActions.changePassword({credentials}));
    }
    ngOnDestroy(): void {
        this.reset();
    }
    reset(): void {
        this.store.dispatch(ProfilePageActions.resetSended());
    }
    onEpit() {
      this.store.dispatch(ProfilePageActions.getEpit());
    }
}
