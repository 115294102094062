import {createSelector, createFeatureSelector, combineReducers, Action} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromWithdrawalPage from './withdrawal-page.reducer';

export const withdrawalFeatureKey = 'withdrawal';

export interface WithdrawalState {
    [fromWithdrawalPage.withdrawalPageFeatureKey]: fromWithdrawalPage.State;
}

export interface State extends fromRoot.State {
    [withdrawalFeatureKey]: WithdrawalState;
}

export function reducers(state: WithdrawalState | undefined, action: Action) {
    return combineReducers({
        [fromWithdrawalPage.withdrawalPageFeatureKey]: fromWithdrawalPage.reducer,
    })(state, action);
}

export const selectWithdrawalState = createFeatureSelector<State, WithdrawalState>(withdrawalFeatureKey);

export const selectWithdrawalPageState = createSelector(
    selectWithdrawalState,
    (state: WithdrawalState) => state.withdrawalPage
);
export const selectError = createSelector(
    selectWithdrawalPageState,
    fromWithdrawalPage.selectError
);
