import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {apiUrl} from 'src/app/common/variables';

@Injectable({
    providedIn: 'root'
})
export class WelcomeService {
    constructor(private http: HttpClient) {}

}
