<div class="col-12 d-flex justify-content-center flex-column">
    <div class="container text-center">
  
        <img src="assets/gfx/dilergologo.svg" class="logodilergo" alt="">
  
        <h1 class="bold">Program wsparcia sprzedaży</h1>
  
        <p>Aplikację możesz pobrać ze sklepów:</p>
  
        <div class="button-group store-buttons d-md-flex d-block justify-content-center">
            <a [href]="AppStore" target="_blank">
                <img src="assets/gfx/shops/appstore.svg" alt="Download on the App Store">
            </a>
            <a [href]="GooglePlay" target="_blank">
                <img src="assets/gfx/shops/google-play.svg" alt="Get it on Google Play">
            </a>
        </div>
    </div>
  </div>