import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AboutPageComponent} from './containers/about-page.component';
import {AuthGuard} from '../auth/services';

const routes: Routes = [{
    path: 'about',
    component: AboutPageComponent,
    // canActivate: [AuthGuard]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AboutRoutingModule {}
