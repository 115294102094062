import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ContactPageComponent} from './containers/contact-page.component';
import {AuthGuard} from '../auth/services';

const routes: Routes = [{
    path: 'contact',
    component: ContactPageComponent,
    // canActivate: [AuthGuard]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ContactRoutingModule {}
