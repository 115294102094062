import {Component} from '@angular/core';
import {Store, select} from '@ngrx/store';
import * as fromWelcome from '../reducers';

@Component({
    template: `
      <app-welcome>
      </app-welcome>`
})
export class WelcomePageComponent{
    constructor(private store: Store<fromWelcome.State>) {}
}
