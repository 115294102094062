
      <div class="col-12 col-md-10 offset-md-2 bg-white inner-content">
        <h3 class="page-title text-center">Kontakt</h3>

        <div class="row">
          <div class="col-md-5 text-center">
              <p class="section-description">Infolinia programu</p>
              <p>Telefon: <a href="tel:+48585859111">+48 58 58 59 111</a></p>
          </div>

          <div class="col text-center">
            <p class="section-description">Formularz kontaktowy</p>

            <div *ngIf="sended" class="alert alert-success text-center">
              <strong>Dziękujemy!</strong> Twoja wiadomość została wysłana.<br/>
              Zajmiemy się sprawą najszybciej jak będzie to możliwe, odpowiedź wyślemy na Twój adres e-mail.
            </div>

            <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!sended">

              <div *ngIf="error" class="alert alert-warning hint-descr text-center mt-4" role="alert">{{error}}</div>

              <div class="form-group">
                <input type="text" class="form-control" placeholder="Temat wiadomości" formControlName="title" required>
              </div>
              <div class="form-group">
                <textarea class="form-control" rows="5" placeholder="Wiadomość" formControlName="message" required></textarea>
              </div>
              <div class="row">
                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block mt-3 mx-auto">Wyślij</button>
                </div>
              </div>

            </form>

          </div>
        </div>

      </div>
