import {Component, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {Contact} from '../models';
import {Utils} from 'src/app/common/utils';
import { Observable } from 'rxjs';
import { resize } from 'src/app/common/resize-img';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls:['./contact.component.scss']
})
export class ContactComponent {
    @Input() error: string | null;
    @Input() sended:boolean;

    @Output() submitted = new EventEmitter<Contact>();

    url:any = '';
    form: FormGroup = new FormGroup({
        title: new FormControl(''),
        message: new FormControl('')
    });
    
    ngOnInit(): void {
    }
    ngOnChanges(changes: SimpleChanges): void {
      let simpleChange = changes['sended'];
      if(simpleChange){
        if(this.sended){
          this.form.disable();
        }
      }
    }
    
    submit() {
        if (this.form.valid) {
          // console.log(this.form.value);
          
            this.submitted.emit(this.form.value);
        }
        else {
            Utils.validateFormFields(this.form);
        }
    }
}