import { Component, AfterViewInit } from '@angular/core';
import {AppStore,GooglePlay} from '../../common/variables'
@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styles: [`
    h1 {
      color: #363940;
      font-size: 3.8rem;
      font-weight: 700;
  }
`]
})
export class WelcomeComponent {
    AppStore = AppStore;
    GooglePlay = GooglePlay;

}
