import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, exhaustMap, map, delay, mergeMap, catchError, switchMap, filter } from 'rxjs/operators';
import { ContactPageActions, ContactApiActions } from '../actions';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { ContactService } from '../service';

@Injectable()
export class ContactEffects {
    sendMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactPageActions.sendMessage),
            switchMap((msg) =>
                this.contactService.sendMessage(msg.msg).pipe(
                    map((res: boolean) => 
                        res?ContactApiActions.sendMessageSuccess()
                        : ContactApiActions.sendMessageFailure({error:'Błąd wysyłania wiadomości, spróbuj ponownie'})
                    ),
                    catchError((error: string) => 
                        of(ContactApiActions.sendMessageFailure({error:'Błąd wysyłania wiadomości, spróbuj ponownie'}))
                    )
                )
            )
        )
    );
    sendMessageSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ContactApiActions.sendMessageSuccess),
        map(() => ContactPageActions.setSended() )
    ));

    constructor(private actions$: Actions, 
        private contactService: ContactService) { }
}