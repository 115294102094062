import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, exhaustMap, map, delay, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ConfirmPageActions, ConfirmApiActions } from '../actions';
import * as fromRoot from '../../reducers';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmService } from '../service';
import { errorType, APIErrorResponse } from 'src/app/common/http/response';
import { ProfileService } from 'src/app/profile/service';
import { Profile } from 'src/app/profile/models/profile';
import { select, Store } from '@ngrx/store';

@Injectable()
export class ConfirmEffects {

    confirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfirmPageActions.confirm),
            withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUrlToken))),
            map(action=>action[1]),
            exhaustMap((token) =>
                this.confirmService.confirm(token).pipe(
                    map((res: boolean) =>{
                        if(res)
                            return ConfirmApiActions.ConfirmSuccess()
                        else
                            return ConfirmApiActions.ConfirmFailure(null)
                        }
                    ),
                    catchError((error: APIErrorResponse) =>
                        of(ConfirmApiActions.ConfirmFailure({ error })),
                    )
                )
            )
        )
    );
    confirmAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfirmPageActions.confirmAccount),
            withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUrlToken))),
            map(action=>{return {token:action[1], account:action[0].account}}),
            exhaustMap((tokenAccount) =>
                this.confirmService.confirmAccount(tokenAccount.token, tokenAccount.account).pipe(
                    map((res: boolean) =>{
                        if(res)
                            return ConfirmApiActions.ConfirmSuccess()
                        else
                            return ConfirmApiActions.ConfirmFailure(null)
                        }
                    ),
                    catchError((error: APIErrorResponse) =>
                        of(ConfirmApiActions.ConfirmFailure({ error })),
                    )
                )
            )
        )
    );
    checkLinkUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfirmPageActions.CheckLinkUser),
            withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUrlToken))),
            map(action=>action[1]),
            exhaustMap((token) =>
                this.confirmService.checkLinkUser(token).pipe(
                    map((res: boolean | number) =>{
                        if(res)
                            return ConfirmApiActions.CheckLinkSuccessUser({param:token, status:(res as number)})
                        else
                            return ConfirmApiActions.CheckLinkFailureUser({error:'Podany link jest nieaktywny'})
                        }
                    ),
                    catchError((error: string) =>
                        of(ConfirmApiActions.CheckLinkFailureUser({ error }))
                    )
                )
            )
        )
    );
    checkLinkFailure$ = createEffect(()=>
        this.actions$.pipe(
            ofType(ConfirmApiActions.CheckLinkFailureUser),
            tap(()=>{this.router.navigate(['/'])})
        ),
        {dispatch: false}
    );
    getProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfirmPageActions.getProfile),
            exhaustMap(() =>
                this.profileService.getProfile().pipe(
                    map((data: Profile) =>
                        ConfirmApiActions.getProfileSuccess({ data }),
                    ),
                    catchError((error: string) =>
                        of(ConfirmApiActions.getProfileFailure({ error })),
                    )
                )
            )
        )
    );
    updateProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfirmPageActions.updateProfile),
            map(action=>action.data),
            exhaustMap((data:Profile) =>
                this.profileService.updateProfile(data).pipe(
                    mergeMap((res: boolean) =>{
                        if(res){
                            return [ConfirmApiActions.updateProfileSuccess({ data }), ConfirmPageActions.getProfile()];
                        }else{ 
                            return of(ConfirmApiActions.updateProfileFailure({ error:'błąd' }));
                        }
                    }),
                    catchError((error: string) =>
                        of(ConfirmApiActions.updateProfileFailure({ error })),
                    )
                )
            )
        )
    );

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private confirmService:ConfirmService,
        private profileService: ProfileService,
        private router: Router
        ) { }
}