import {Input, Output, EventEmitter, Component} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {Utils} from '../../common/utils';

@Component({
    selector: 'app-after-register',
    template: `
      <div class="col bg-white inner-content">

        <ng-container *ngIf="error; else success">

          <div class="col-sm-12 offset-sm-0 col-md-8 offset-md-2" [innerHTML]="error">
            <h3 class="pt-100-lg mb-0"><span class="text-red">Nie udało się</span> zweryfikować Twojej tożsamości w ERGO Hestii. Twoje dane zostały usunięte.</h3>
            <h3 class="pb-4 pt-3"> Sprawdź czy formularz nie zawiera błędnych danych, a w przypadku dalszych problemów skontaktuj się ze swoim Regionalnym Kierownikiem Sprzedaży.</h3>
          </div>

          <div class="col-sm-12 offset-sm-0 col-md-8 offset-md-2">
            <a [routerLink]="[ '/register' ]" class="btn btn-primary ml-0">Spróbuj jeszcze raz</a>
          </div>
        </ng-container>

        <ng-template #success>

          <div class="col-sm-12 offset-sm-0 col-md-8 offset-md-2">
            <h3 class="pt-100-lg"><span class="text-red">Gratulujemy!</span></h3>
            <h3 class="pb-4">Za Tobą najtrudniejszy krok. Za chwilę otrzymasz na swój adres e-mail link potwierdzający Twoją tożsamość. Kliknij w niego i dokończ rejestrację w ERGO Hestia DILER GO!</h3>
          </div>

        </ng-template>

      </div>`
})

export class AfterRegisterComponent {
    @Input() error: string | null;


    constructor() {}

}
