import {select, Store} from '@ngrx/store';
import {Verification} from '../models/register';
import * as fromRegister from '../reducers';
import {RegisterPageActions} from '../actions';
import {Component} from '@angular/core';

@Component({
    template: `
    <app-verification-form
      (submitted)="onSubmit($event)"
      [validationError]="validationError$ | async"
      [error]="error$ | async">
    </app-verification-form>`
})
export class VerificationPageComponent {
    validationError$ = this.store.pipe(select(fromRegister.selectRegisterPageValidationError));
    error$ = this.store.pipe(select(fromRegister.selectRegisterError));

    constructor(protected store: Store<fromRegister.State>) {
       
    }

    onSubmit(verification: Verification) {
        this.store.dispatch(RegisterPageActions.verification({verification}));
    }
}
