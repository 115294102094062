import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WelcomePageComponent} from './containers';
import {AuthGuard} from '../auth/services';

const routes: Routes = [{
    
    path: 'welcome',
    component: WelcomePageComponent,
    // canActivate: [AuthGuard]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WelcomeRoutingModule {}
