//GLOBAL API adress:

// export const apiUrl: string="http://192.168.1.137:4200/";
//export const apiUrl: string="http://192.168.1.45:4200/"; //Pawel local
//  export const apiUrl: string="http://localhost:4200/"
// export const apiUrl: string="https://app-test.ipunkt.app/"

// export const apiUrl: string="https://app-test.dilergo.app/"
export const apiUrl: string="https://app.dilergo.app/"

//Stores:

export const AppStore: string= "https://apps.apple.com/us/app/id1533333738";
export const GooglePlay: string= "https://play.google.com/store/apps/details?id=pl.dilergo.ergohestia";