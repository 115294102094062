import {AuthApiActions, AuthActions} from '../actions';
import {Token} from '../models/auth';
import {createReducer, on} from '@ngrx/store';

export const statusFeatureKey = 'status';

export interface State {
    token: Token | null;
}

export const initialState: State = {
    token: null,
};

export const reducer = createReducer(
    initialState,
    on(
        AuthApiActions.loginSuccess,
        AuthApiActions.refreshTokenSuccess,
        AuthApiActions.clientCredentialsTokenSuccess,
        (state, token) => ({...state, token})),
    on(
        AuthApiActions.changePasswordSuccess,
        state => ({token: {...state.token, resetPassword: false}})
    ),
    on(AuthActions.logout, () => initialState)
);

export const selectToken = (state: State) => state.token;
