import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainPageComponent} from './containers/main-page.component';
import { MainGuard } from './services/main-guard.service';

const routes: Routes = [{path: '', component: MainPageComponent, canActivate: [MainGuard]}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MainRoutingModule {}
