export const urzedy:string[]=[
    'Dolnośląski Urząd Skarbowy we Wrocławiu [0271]',
    'Drugi Mazowiecki Urząd Skarbowy w Warszawie [1472]',
    'Drugi Śląski Urząd Skarbowy w Bielsku-Białej [2472]',
    'Drugi Urząd Skarbowy Kraków [1228]',
    'Drugi Urząd Skarbowy Łódź-Bałuty [1009]',
    'Drugi Urząd Skarbowy Łódź-Górna [1011]',
    'Drugi Urząd Skarbowy Warszawa-Śródmieście [1436]',
    'Drugi Urząd Skarbowy w Białymstoku [2004]',
    'Drugi Urząd Skarbowy w Bielsku-Białej [2404]',
    'Drugi Urząd Skarbowy w Bydgoszczy [0405]',
    'Drugi Urząd Skarbowy w Częstochowie [2410]',
    'Drugi Urząd Skarbowy w Gdańsku [2206]',
    'Drugi Urząd Skarbowy w Gdyni [2209]',
    'Drugi Urząd Skarbowy w Gliwicach [2413]',
    'Drugi Urząd Skarbowy w Kaliszu [3008]',
    'Drugi Urząd Skarbowy w Katowicach [2417]',
    'Drugi Urząd Skarbowy w Kielcach [2605]',
    'Drugi Urząd Skarbowy w Koszalinie [3211]',
    'Drugi Urząd Skarbowy w Lublinie [0611]',
    'Drugi Urząd Skarbowy w Opolu [1610]',
    'Drugi Urząd Skarbowy w Radomiu [1425]',
    'Drugi Urząd Skarbowy w Rzeszowie [1822]',
    'Drugi Urząd Skarbowy w Szczecinie [3216]',
    'Drugi Urząd Skarbowy w Tarnowie [1224]',
    'Drugi Urząd Skarbowy w Toruniu [0417]',
    'Drugi Urząd Skarbowy w Zielonej Górze [0809]',
    'Drugi Wielkopolski Urząd Skarbowy w Kaliszu [3072]',
    'Kujawsko-Pomorski Urząd Skarbowy w Bydgoszczy [0471]',
    'Łódzki Urząd Skarbowy w Łodzi [1071]',
    'Lubelski Urząd Skarbowy w Lublinie [0671]',
    'Lubuski Urząd Skarbowy w Zielonej Górze [0871]',
    'Małopolski Urząd Skarbowy w Krakowie [1271]',
    'Opolski Urząd Skarbowy w Opolu [1671]',
    'Pierwszy Mazowiecki Urząd Skarbowy w Warszawie [1471]',
    'Pierwszy Śląski Urząd Skarbowy w Sosnowcu [2471]',
    'Pierwszy Urząd Skarbowy Łódź-Bałuty [1008]',
    'Pierwszy Urząd Skarbowy Łódź-Górna [1010]',
    'Pierwszy Urząd Skarbowy Warszawa-Śródmieście [1435]',
    'Pierwszy Urząd Skarbowy w Białymstoku [2003]',
    'Pierwszy Urząd Skarbowy w Bielsku-Białej [2403]',
    'Pierwszy Urząd Skarbowy w Bydgoszczy [0404]',
    'Pierwszy Urząd Skarbowy w Częstochowie [2409]',
    'Pierwszy Urząd Skarbowy we Wrocławiu [0229]',
    'Pierwszy Urząd Skarbowy w Gdańsku [2205]',
    'Pierwszy Urząd Skarbowy w Gdyni [2208]',
    'Pierwszy Urząd Skarbowy w Gliwicach [2412]',
    'Pierwszy Urząd Skarbowy w Kaliszu [3007]',
    'Pierwszy Urząd Skarbowy w Katowicach [2416]',
    'Pierwszy Urząd Skarbowy w Kielcach [2604]',
    'Pierwszy Urząd Skarbowy w Koszalinie [3210]',
    'Pierwszy Urząd Skarbowy w Krakowie [1207]',
    'Pierwszy Urząd Skarbowy w Lublinie [0610]',
    'Pierwszy Urząd Skarbowy w Opolu [1609]',
    'Pierwszy Urząd Skarbowy w Poznaniu [3023]',
    'Pierwszy Urząd Skarbowy w Radomiu [1424]',
    'Pierwszy Urząd Skarbowy w Rzeszowie [1816]',
    'Pierwszy Urząd Skarbowy w Szczecinie [3215]',
    'Pierwszy Urząd Skarbowy w Tarnowie [1223]',
    'Pierwszy Urząd Skarbowy w Toruniu [0416]',
    'Pierwszy Urząd Skarbowy w Zielonej Górze [0808]',
    'Pierwszy Wielkopolski Urząd Skarbowy w Poznaniu [3071]',
    'Podkarpacki Urząd Skarbowy w Rzeszowie [1871]',
    'Podlaski Urząd Skarbowy w Białymstoku [2071]',
    'Pomorski Urząd Skarbowy w Gdańsku [2271]',
    'Świętokrzyski Urząd Skarbowy w Kielcach [2671]',
    'Trzeci Mazowiecki Urząd Skarbowy w Radomiu [1473]',
    'Trzeci Urząd Skarbowy Warszawa-Śródmieście [1449]',
    'Trzeci Urząd Skarbowy w Bydgoszczy [0406]',
    'Trzeci Urząd Skarbowy w Gdańsku [2207]',
    'Trzeci Urząd Skarbowy w Lublinie [0612]',
    'Trzeci Urząd Skarbowy w Szczecinie [3217]',
    'Urząd Skarbowy Kraków-Krowodrza [1208]',
    'Urząd Skarbowy Kraków-Nowa Huta [1209]',
    'Urząd Skarbowy Kraków-Podgórze [1210]',
    'Urząd Skarbowy Kraków-Prądnik [1211]',
    'Urząd Skarbowy Kraków-Śródmieście [1213]',
    'Urząd Skarbowy Kraków-Stare Miasto [1212]',
    'Urząd Skarbowy Łódź-Polesie [1012]',
    'Urząd Skarbowy Łódź-Śródmieście [1013]',
    'Urząd Skarbowy Łódź-Widzew [1014]',
    'Urząd Skarbowy Poznań-Grunwald [3020]',
    'Urząd Skarbowy Poznań-Jeżyce [3021]',
    'Urząd Skarbowy Poznań-Nowe Miasto [3022]',
    'Urząd Skarbowy Poznań-Wilda [3026]',
    'Urząd Skarbowy Poznań-Winogrady [3025]',
    'Urząd Skarbowy w Aleksandrowie Kujawskim [0402]',
    'Urząd Skarbowy Warszawa-Bemowo [1431]',
    'Urząd Skarbowy Warszawa-Bielany [1432]',
    'Urząd Skarbowy Warszawa-Mokotów [1433]',
    'Urząd Skarbowy Warszawa-Praga [1434]',
    'Urząd Skarbowy Warszawa-Targówek [1437]',
    'Urząd Skarbowy Warszawa-Ursynów [1438]',
    'Urząd Skarbowy Warszawa-Wawer [1439]',
    'Urząd Skarbowy Warszawa-Wola [1440]',
    'Urząd Skarbowy w Augustowie [2002]',
    'Urząd Skarbowy w Bartoszycach [2802]',
    'Urząd Skarbowy w Będzinie [2402]',
    'Urząd Skarbowy w Bełchatowie [1002]',
    'Urząd Skarbowy w Białej Podlaskiej [0602]',
    'Urząd Skarbowy w Białobrzegach [1402]',
    'Urząd Skarbowy w Białogardzie [3202]',
    'Urząd Skarbowy w Bielsku Podlaskim [2005]',
    'Urząd Skarbowy w Biłgoraju [0603]',
    'Urząd Skarbowy w Bochni [1202]',
    'Urząd Skarbowy w Bolesławcu [0202]',
    'Urząd Skarbowy w Braniewie [2803]',
    'Urząd Skarbowy w Brodnicy [0403]',
    'Urząd Skarbowy w Brzegu [1602]',
    'Urząd Skarbowy w Brzesku [1203]',
    'Urząd Skarbowy w Brzezinach [1003]',
    'Urząd Skarbowy w Brzozowie [1802]',
    'Urząd Skarbowy w Busku-Zdroju [2602]',
    'Urząd Skarbowy w Bystrzycy Kłodzkiej [0203]',
    'Urząd Skarbowy w Bytomiu [2405]',
    'Urząd Skarbowy w Bytowie [2202]',
    'Urząd Skarbowy w Chełmie [0604]',
    'Urząd Skarbowy w Chełmnie [0407]',
    'Urząd Skarbowy w Chodzieży [3037]',
    'Urząd Skarbowy w Chojnicach [2203]',
    'Urząd Skarbowy w Chorzowie [2406]',
    'Urząd Skarbowy w Choszcznie [3203]',
    'Urząd Skarbowy w Chrzanowie [1204]',
    'Urząd Skarbowy w Ciechanowie [1403]',
    'Urząd Skarbowy w Cieszynie [2407]',
    'Urząd Skarbowy w Czarnkowie [3002]',
    'Urząd Skarbowy w Czechowicach-Dziedzicach [2408]',
    'Urząd Skarbowy w Człuchowie [2204]',
    'Urząd Skarbowy w Dąbrowie Górniczej [2411]',
    'Urząd Skarbowy w Dąbrowie Tarnowskiej [1205]',
    'Urząd Skarbowy w Dębicy [1803]',
    'Urząd Skarbowy w Drawsku Pomorskim [3204]',
    'Urząd Skarbowy w Drezdenku [0812]',
    'Urząd Skarbowy w Działdowie [2804]',
    'Urząd Skarbowy w Dzierżoniowie [0204]',
    'Urząd Skarbowy w Elblągu [2805]',
    'Urząd Skarbowy w Ełku [2806]',
    'Urząd Skarbowy we Włocławku [0420]',
    'Urząd Skarbowy we Włodawie [0619]',
    'Urząd Skarbowy we Włoszczowie [2615]',
    'Urząd Skarbowy we Wrześni [3035]',
    'Urząd Skarbowy we Wschowie [0814]',
    'Urząd Skarbowy w Garwolinie [1404]',
    'Urząd Skarbowy w Giżycku [2807]',
    'Urząd Skarbowy w Głogowie [0205]',
    'Urząd Skarbowy w Głownie [1004]',
    'Urząd Skarbowy w Głubczycach [1603]',
    'Urząd Skarbowy w Gnieźnie [3003]',
    'Urząd Skarbowy w Goleniowie [3205]',
    'Urząd Skarbowy w Golubiu-Dobrzyniu [0422]',
    'Urząd Skarbowy w Gorlicach [1206]',
    'Urząd Skarbowy w Górze [0233]',
    'Urząd Skarbowy w Gorzowie Wielkopolskim [0802]',
    'Urząd Skarbowy w Gostyninie [1405]',
    'Urząd Skarbowy w Gostyniu [3004]',
    'Urząd Skarbowy w Grajewie [2006]',
    'Urząd Skarbowy w Grodzisku Mazowieckim [1406]',
    'Urząd Skarbowy w Grodzisku Wielkopolskim [3005]',
    'Urząd Skarbowy w Grójcu [1407]',
    'Urząd Skarbowy w Grudziądzu [0408]',
    'Urząd Skarbowy w Gryficach [3206]',
    'Urząd Skarbowy w Gryfinie [3207]',
    'Urząd Skarbowy w Hajnówce [2015]',
    'Urząd Skarbowy w Hrubieszowie [0605]',
    'Urząd Skarbowy w Iławie [2808]',
    'Urząd Skarbowy w Inowrocławiu [0409]',
    'Urząd Skarbowy w Janowie Lubelskim [0606]',
    'Urząd Skarbowy w Jarocinie [3006]',
    'Urząd Skarbowy w Jarosławiu [1804]',
    'Urząd Skarbowy w Jaśle [1805]',
    'Urząd Skarbowy w Jastrzębiu-Zdroju [2414]',
    'Urząd Skarbowy w Jaworze [0206]',
    'Urząd Skarbowy w Jaworznie [2415]',
    'Urząd Skarbowy w Jędrzejowie [2603]',
    'Urząd Skarbowy w Jeleniej Górze [0207]',
    'Urząd Skarbowy w Kamieniu Pomorskim [3208]',
    'Urząd Skarbowy w Kamiennej Górze [0208]',
    'Urząd Skarbowy w Kartuzach [2210]',
    'Urząd Skarbowy w Kazimierzy Wielkiej [2614]',
    'Urząd Skarbowy w Kędzierzynie-Koźlu [1604]',
    'Urząd Skarbowy w Kępnie [3009]',
    'Urząd Skarbowy w Kętrzynie [2809]',
    'Urząd Skarbowy w Kłobucku [2418]',
    'Urząd Skarbowy w Kłodzku [0209]',
    'Urząd Skarbowy w Kluczborku [1605]',
    'Urząd Skarbowy w Kolbuszowej [1806]',
    'Urząd Skarbowy w Kole [3010]',
    'Urząd Skarbowy w Kolnie [2007]',
    'Urząd Skarbowy w Kołobrzegu [3209]',
    'Urząd Skarbowy w Koninie [3011]',
    'Urząd Skarbowy w Końskich [2606]',
    'Urząd Skarbowy w Kościanie [3012]',
    'Urząd Skarbowy w Kościerzynie [2211]',
    'Urząd Skarbowy w Kozienicach [1408]',
    'Urząd Skarbowy w Krapkowicach [1613]',
    'Urząd Skarbowy w Kraśniku [0608]',
    'Urząd Skarbowy w Krasnymstawie [0607]',
    'Urząd Skarbowy w Krośnie [1807]',
    'Urząd Skarbowy w Krośnie Odrzańskim [0803]',
    'Urząd Skarbowy w Krotoszynie [3013]',
    'Urząd Skarbowy w Kutnie [1005]',
    'Urząd Skarbowy w Kwidzynie [2212]',
    'Urząd Skarbowy w Łańcucie [1811]',
    'Urząd Skarbowy w Łasku [1006]',
    'Urząd Skarbowy w Lęborku [2213]',
    'Urząd Skarbowy w Łęcznej [0621]',
    'Urząd Skarbowy w Łęczycy [1028]',
    'Urząd Skarbowy w Legionowie [1409]',
    'Urząd Skarbowy w Legnicy [0210]',
    'Urząd Skarbowy w Lesku [1808]',
    'Urząd Skarbowy w Lesznie [3014]',
    'Urząd Skarbowy w Leżajsku [1809]',
    'Urząd Skarbowy w Limanowej [1214]',
    'Urząd Skarbowy w Lipnie [0410]',
    'Urząd Skarbowy w Lipsku [1447]',
    'Urząd Skarbowy w Łomży [2008]',
    'Urząd Skarbowy w Łosicach [1410]',
    'Urząd Skarbowy w Łowiczu [1007]',
    'Urząd Skarbowy w Lubaczowie [1810]',
    'Urząd Skarbowy w Lubaniu [0211]',
    'Urząd Skarbowy w Lubartowie [0609]',
    'Urząd Skarbowy w Lubinie [0212]',
    'Urząd Skarbowy w Lublińcu [2419]',
    'Urząd Skarbowy w Łukowie [0613]',
    'Urząd Skarbowy w Lwówku Śląskim [0213]',
    'Urząd Skarbowy w Makowie Mazowieckim [1411]',
    'Urząd Skarbowy w Malborku [2214]',
    'Urząd Skarbowy w Miechowie [1215]',
    'Urząd Skarbowy w Międzychodzie [3015]',
    'Urząd Skarbowy w Międzyrzeczu [0804]',
    'Urząd Skarbowy w Mielcu [1812]',
    'Urząd Skarbowy w Mikołowie [2420]',
    'Urząd Skarbowy w Miliczu [0214]',
    'Urząd Skarbowy w Mińsku Mazowieckim [1412]',
    'Urząd Skarbowy w Mławie [1413]',
    'Urząd Skarbowy w Mogilnie [0411]',
    'Urząd Skarbowy w Mońkach [2009]',
    'Urząd Skarbowy w Myślenicach [1216]',
    'Urząd Skarbowy w Myśliborzu [3212]',
    'Urząd Skarbowy w Mysłowicach [2421]',
    'Urząd Skarbowy w Myszkowie [2422]',
    'Urząd Skarbowy w Nakle nad Notecią [0412]',
    'Urząd Skarbowy w Namysłowie [1606]',
    'Urząd Skarbowy w Nidzicy [2810]',
    'Urząd Skarbowy w Nisku [1823]',
    'Urząd Skarbowy w Nowej Rudzie [0215]',
    'Urząd Skarbowy w Nowej Soli [0805]',
    'Urząd Skarbowy w Nowym Dworze Mazowieckim [1414]',
    'Urząd Skarbowy w Nowym Mieście Lubawskim [2811]',
    'Urząd Skarbowy w Nowym Sączu [1217]',
    'Urząd Skarbowy w Nowym Targu [1218]',
    'Urząd Skarbowy w Nowym Tomyślu [3016]',
    'Urząd Skarbowy w Nysie [1607]',
    'Urząd Skarbowy w Obornikach [3038]',
    'Urząd Skarbowy w Oławie [0217]',
    'Urząd Skarbowy w Olecku [2812]',
    'Urząd Skarbowy w Oleśnicy [0216]',
    'Urząd Skarbowy w Oleśnie [1608]',
    'Urząd Skarbowy w Olkuszu [1219]',
    'Urząd Skarbowy w Olsztynie [2813]',
    'Urząd Skarbowy w Opatowie [2607]',
    'Urząd Skarbowy w Opocznie [1015]',
    'Urząd Skarbowy w Opolu Lubelskim [0614]',
    'Urząd Skarbowy w Ostródzie [2814]',
    'Urząd Skarbowy w Ostrołęce [1415]',
    'Urząd Skarbowy w Ostrowcu Świętokrzyskim [2608]',
    'Urząd Skarbowy w Ostrowie Wielkopolskim [3017]',
    'Urząd Skarbowy w Ostrowi Mazowieckiej [1416]',
    'Urząd Skarbowy w Ostrzeszowie [3018]',
    'Urząd Skarbowy w Oświęcimiu [1220]',
    'Urząd Skarbowy w Otwocku [1417]',
    'Urząd Skarbowy w Pabianicach [1016]',
    'Urząd Skarbowy w Pajęcznie [1029]',
    'Urząd Skarbowy w Parczewie [0615]',
    'Urząd Skarbowy w Piasecznie [1418]',
    'Urząd Skarbowy w Piekarach Śląskich [2423]',
    'Urząd Skarbowy w Pile [3019]',
    'Urząd Skarbowy w Pińczowie [2609]',
    'Urząd Skarbowy w Piotrkowie Trybunalskim [1017]',
    'Urząd Skarbowy w Piszu [2815]',
    'Urząd Skarbowy w Pleszewie [3039]',
    'Urząd Skarbowy w Płocku [1419]',
    'Urząd Skarbowy w Płońsku [1420]',
    'Urząd Skarbowy w Poddębicach [1018]',
    'Urząd Skarbowy w Polkowicach [0234]',
    'Urząd Skarbowy w Proszowicach [1221]',
    'Urząd Skarbowy w Prudniku [1611]',
    'Urząd Skarbowy w Pruszczu Gdańskim [2221]',
    'Urząd Skarbowy w Pruszkowie [1421]',
    'Urząd Skarbowy w Przasnyszu [1422]',
    'Urząd Skarbowy w Przemyślu [1813]',
    'Urząd Skarbowy w Przeworsku [1814]',
    'Urząd Skarbowy w Przysusze [1448]',
    'Urząd Skarbowy w Pszczynie [2424]',
    'Urząd Skarbowy w Pucku [2215]',
    'Urząd Skarbowy w Puławach [0616]',
    'Urząd Skarbowy w Pułtusku [1423]',
    'Urząd Skarbowy w Pyrzycach [3213]',
    'Urząd Skarbowy w Raciborzu [2425]',
    'Urząd Skarbowy w Radomsku [1019]',
    'Urząd Skarbowy w Radziejowie [0413]',
    'Urząd Skarbowy w Radzyniu Podlaskim [0617]',
    'Urząd Skarbowy w Rawiczu [3027]',
    'Urząd Skarbowy w Rawie Mazowieckiej [1020]',
    'Urząd Skarbowy Wrocław-Fabryczna [0224]',
    'Urząd Skarbowy Wrocław-Krzyki [0225]',
    'Urząd Skarbowy Wrocław-Psie Pole [0226]',
    'Urząd Skarbowy Wrocław-Śródmieście [0228]',
    'Urząd Skarbowy Wrocław-Stare Miasto [0227]',
    'Urząd Skarbowy w Ropczycach [1815]',
    'Urząd Skarbowy w Rudzie Śląskiej [2426]',
    'Urząd Skarbowy w Rybniku [2427]',
    'Urząd Skarbowy w Rykach [0622]',
    'Urząd Skarbowy w Rypinie [0414]',
    'Urząd Skarbowy w Sandomierzu [2610]',
    'Urząd Skarbowy w Sanoku [1817]',
    'Urząd Skarbowy w Sępólnie Krajeńskim [0423]',
    'Urząd Skarbowy w Siedlcach [1426]',
    'Urząd Skarbowy w Siemianowicach Śląskich [2428]',
    'Urząd Skarbowy w Siemiatyczach [2010]',
    'Urząd Skarbowy w Sieradzu [1021]',
    'Urząd Skarbowy w Sierpcu [1427]',
    'Urząd Skarbowy w Skarżysku-Kamiennej [2611]',
    'Urząd Skarbowy w Skierniewicach [1022]',
    'Urząd Skarbowy w Słubicach [0806]',
    'Urząd Skarbowy w Słupcy [3028]',
    'Urząd Skarbowy w Słupsku [2216]',
    'Urząd Skarbowy w Sochaczewie [1428]',
    'Urząd Skarbowy w Sokółce [2011]',
    'Urząd Skarbowy w Sokołowie Podlaskim [1429]',
    'Urząd Skarbowy w Sopocie [2217]',
    'Urząd Skarbowy w Sosnowcu [2429]',
    'Urząd Skarbowy w Śremie [3030]',
    'Urząd Skarbowy w Środzie Śląskiej [0219]',
    'Urząd Skarbowy w Środzie Wielkopolskiej [3031]',
    'Urząd Skarbowy w Stalowej Woli [1818]',
    'Urząd Skarbowy w Starachowicach [2612]',
    'Urząd Skarbowy w Stargardzie [3214]',
    'Urząd Skarbowy w Starogardzie Gdańskim [2218]',
    'Urząd Skarbowy w Staszowie [2613]',
    'Urząd Skarbowy w Strzelcach Opolskich [1612]',
    'Urząd Skarbowy w Strzelinie [0218]',
    'Urząd Skarbowy w Strzyżowie [1819]',
    'Urząd Skarbowy w Suchej Beskidzkiej [1222]',
    'Urząd Skarbowy w Sulęcinie [0813]',
    'Urząd Skarbowy w Suwałkach [2012]',
    'Urząd Skarbowy w Świdnicy [0220]',
    'Urząd Skarbowy w Świebodzinie [0807]',
    'Urząd Skarbowy w Świeciu [0415]',
    'Urząd Skarbowy w Świnoujściu [3219]',
    'Urząd Skarbowy w Szamotułach [3029]',
    'Urząd Skarbowy w Szczecinku [3218]',
    'Urząd Skarbowy w Szczytnie [2816]',
    'Urząd Skarbowy w Szydłowcu [1430]',
    'Urząd Skarbowy w Tarnobrzegu [1820]',
    'Urząd Skarbowy w Tarnowskich Górach [2430]',
    'Urząd Skarbowy w Tczewie [2219]',
    'Urząd Skarbowy w Tomaszowie Lubelskim [0618]',
    'Urząd Skarbowy w Tomaszowie Mazowieckim [1023]',
    'Urząd Skarbowy w Trzebnicy [0221]',
    'Urząd Skarbowy w Tucholi [0418]',
    'Urząd Skarbowy w Turku [3032]',
    'Urząd Skarbowy w Tychach [2431]',
    'Urząd Skarbowy w Ustrzykach Dolnych [1821]',
    'Urząd Skarbowy w Wąbrzeźnie [0419]',
    'Urząd Skarbowy w Wadowicach [1225]',
    'Urząd Skarbowy w Wągrowcu [3033]',
    'Urząd Skarbowy w Wałbrzychu [0222]',
    'Urząd Skarbowy w Wałczu [3220]',
    'Urząd Skarbowy w Węgrowie [1441]',
    'Urząd Skarbowy w Wejherowie [2220]',
    'Urząd Skarbowy w Wieliczce [1226]',
    'Urząd Skarbowy w Wieluniu [1024]',
    'Urząd Skarbowy w Wieruszowie [1027]',
    'Urząd Skarbowy w Wodzisławiu Śląskim [2432]',
    'Urząd Skarbowy w Wołominie [1442]',
    'Urząd Skarbowy w Wołowie [0223]',
    'Urząd Skarbowy w Wolsztynie [3034]',
    'Urząd Skarbowy w Wysokiem Mazowieckiem [2013]',
    'Urząd Skarbowy w Wyszkowie [1443]',
    'Urząd Skarbowy w Ząbkowicach Śląskich [0230]',
    'Urząd Skarbowy w Zabrzu [2433]',
    'Urząd Skarbowy w Żaganiu [0810]',
    'Urząd Skarbowy w Zakopanem [1227]',
    'Urząd Skarbowy w Zambrowie [2014]',
    'Urząd Skarbowy w Zamościu [0620]',
    'Urząd Skarbowy w Żarach [0811]',
    'Urząd Skarbowy w Zawierciu [2434]',
    'Urząd Skarbowy w Zduńskiej Woli [1025]',
    'Urząd Skarbowy w Zgierzu [1026]',
    'Urząd Skarbowy w Zgorzelcu [0231]',
    'Urząd Skarbowy w Złotoryi [0232]',
    'Urząd Skarbowy w Złotowie [3036]',
    'Urząd Skarbowy w Żninie [0421]',
    'Urząd Skarbowy w Żorach [2435]',
    'Urząd Skarbowy w Żurominie [1445]',
    'Urząd Skarbowy w Zwoleniu [1444]',
    'Urząd Skarbowy w Żyrardowie [1446]',
    'Urząd Skarbowy w Żywcu [2436]',
    'Warmińsko-Mazurski Urząd Skarbowy w Olsztynie [2871]',
    'Zachodniopomorski Urząd Skarbowy w Szczecinie [3271]',
    
];