import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {WithdrawalRoutingModule} from './withdrawal-routing.module';
import {WithdrawalEffects} from './effects';
import * as fromWithdrawal from './reducers';
import {WithdrawalPageComponent} from './containers';
import {WithdrawalComponent} from './components';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const COMPONENTS = [
    WithdrawalComponent,
    WithdrawalPageComponent
];

@NgModule({
    imports: [
        CommonModule,
        WithdrawalRoutingModule,
        StoreModule.forFeature(fromWithdrawal.withdrawalFeatureKey, fromWithdrawal.reducers),
        EffectsModule.forFeature([WithdrawalEffects])
    ],
    declarations: COMPONENTS,
    providers: [
    ]
})
export class WithdrawalModule {}
