import {createFeatureSelector, createSelector, Action, combineReducers} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromProfile from './profile-page.reducer';

export const profileFeatureKey = 'profile';

export interface ProfileState {
    [fromProfile.profilePageFeatureKey]: fromProfile.State;
}

export interface State extends fromRoot.State {
    [profileFeatureKey]: ProfileState;
}

export function reducers(state: ProfileState | undefined, action: Action) {
    return combineReducers({
        [fromProfile.profilePageFeatureKey]: fromProfile.reducer
    })(state, action);
}

export const selectProfileState = createFeatureSelector<State, ProfileState>(profileFeatureKey);

export const selectProfilePageState = createSelector(
    selectProfileState,
    state => state.profilePage
);
export const selectProfile = createSelector(
    selectProfilePageState,
    fromProfile.selectProfile
);
export const selectError = createSelector(
    selectProfilePageState,
    fromProfile.selectError
);
export const selectRegisterPageValidationError = createSelector(
    selectProfilePageState,
    fromProfile.selectValidationError
);
export const selectPassSended = createSelector(
    selectProfilePageState,
    fromProfile.selectPassSended
);
export const selectSended = createSelector(
    selectProfilePageState,
    fromProfile.selectSended
);
export const selectEpitAvailable = createSelector(
  selectProfilePageState,
  fromProfile.selectEpitAvailable
);
