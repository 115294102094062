<!-- <div class="main-content">
  <div class="container">
    <div class="row"> -->
      <div class="col-12 col-md-10 offset-md-2 bg-white inner-content">

        <h3 class="page-title text-center">O aplikacji</h3>

        <div class="container container-narrow">
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" heading="Instalacja aplikacji na telefonie">
              <ol class="manual-list">
                <li>Jeżeli oglądasz tę stronę na swoim telefonie, aplikację możesz pobrać bezpośrednio z linków poniżej:
                  <a href=""><img src="assets/gfx/dilergo-about/appstore.png" alt="" class="shop-icon" style="margin-right: 15px"></a>
                  <a href=""><img src="assets/gfx/dilergo-about/googleplay.png" alt="" class="shop-icon"></a>
                </li>
                <li>Jeżeli oglądasz na swoim komputerze, to weź swój telefon i znajdź sklep:
                  <ol class="manual-sublist">
                    <li>Google Play (dla systemów Android)</li>
                    <li>App Store (dla iPhone'ów z systemem iOS)</li>
                  </ol>
                </li>
                <li>W sklepie wyszukaj: DilerGo!</li>
                <li>Kliknij odpowiednio Zainstaluj (Android) lub Pobierz (iOS)</li>
                <li>Następnie wprowadź swój login i hasło. Login i hasło jest takie samo jak przy logowaniu na stronie.</li>
                <li>Uwaga! W przypadku telefonu iPhone: pojawi się ekran jak poniżej.<br>
                  <img src="assets/gfx/dilergo-about/Dialog.png" class="img-fluid" alt="" width="250"><br>
                  Kliknij "Pozwalaj"</li>
                <li><p>Po poprawnym zalogowaniu zobaczysz swój ekran główny aplikacji:</p>
                  <img src="assets/gfx/dilergo-about/8.png" alt="" width="250"></li>
              </ol>
            </accordion-panel>
          </accordion>
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" heading="Nawigacja w aplikacji">
              <ol class="manual-list">
                <li>
                  <p>Poruszanie się między sekcjami odbywa się w menu, które pojawia się po kliknięciu hamburgera w prawym dolnym rogu.</p>
                  <img src="assets/gfx/dilergo-about/1.png" width="250" alt=""><br>
                  <img src="assets/gfx/dilergo-about/2.png" width="250" alt=""><br>
                </li>
                <li>
                  <p>Dodatkowe funkcjonalności znajdziesz klikając w zębate koło ustawień w menu.</p>
                  <img src="assets/gfx/dilergo-about/3.png" width="250" alt="">
                </li>
              </ol>
              <p>W ustawieniach możesz znaleźć 3 opcje do wyboru:</p>
              <ol class="manual-list">
                <li><p>Włączenie i wyłączenie pojawień push,</p></li>
                <li>
                  <p>Widok menu - ikony lub lista,</p>
                  <img src="assets/gfx/dilergo-about/4.png" width="250" alt="">
                </li>
                <li>
                  <p>Jasny lub ciemny motyw aplikacji.</p>
                  <img src="assets/gfx/dilergo-about/5.png" width="250" alt="">
                </li>
              </ol>
            </accordion-panel>
          </accordion>

          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" heading="Strona główna">
              <p>Strona główna jest podzielona na 3 sekcje.</p>
              <ol class="manual-list">
                <li>W górnej części podana jest ilość środków jakie obecnie są do wykorzystania.</li>
                <li>W centralnej części widoczne są wszystkie nowe lub trwające akcje, które można przesuwać palcem w lewo <-> prawo w celu sprawdzenia wszystkich.</li>
                <li>
                  <p>W dolnej części znajdują się najnowsze wiadomości.</p>
                  <img src="assets/gfx/dilergo-about/6.png" width="250" alt="" style="margin-right: 30px">
                  <img src="assets/gfx/dilergo-about/7.png" width="250" alt="">
                </li>
              </ol>
            </accordion-panel>
          </accordion>

          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" heading="Akcje">
              <p>W zakładce Akcje widoczne są aktualne oraz zakończone akcje.</p>
              <ol class="manual-list">
                <li>
                  <p>Gdy pojawi się nowa Akcja na dole znajduje się przycisk "Szczegóły akcji"</p>
                  <img src="assets/gfx/dilergo-about/8.png" width="250" alt="">
                </li>
                <li>
                  <p>Po kliknięciu w "Szczegóły akcji" pojawia się pełny opis wraz z warunkami i innymi informacjami. Pod zdjęciem znajduje się przycisk "Przystępuję do akcji". Klikając w niego akceptujemy warunki Akcji i automatycznie zaczynamy brać w niej udział.</p>
                  <img src="assets/gfx/dilergo-about/9.png" width="250" alt="">
                </li>
                <li>
                  <p>Widok Akcji, w której bierzemy udział pokazuje aktualny progres uczestnika.</p>
                  <img src="assets/gfx/dilergo-about/10.png" width="250" alt="" style="margin-right: 20px">
                  <img src="assets/gfx/dilergo-about/11.png" width="250" alt="" style="margin-right: 20px">
                  <img src="assets/gfx/dilergo-about/12.png" width="250" alt="">
                </li>
                <li>
                  <p>Przycisk "Lista polis", przenosi do sekcji, w której widzimy wszystkie polisy, zaliczone do danej akcji.</p>
                  <img src="assets/gfx/dilergo-about/13.png" width="250" alt="">
                </li>
              </ol>
            </accordion-panel>
          </accordion>

          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" heading="Ranking">
              <p>W zakładce Rankingi widzimy listę aktualnych rankingów, w których uczestnik bierze udział.</p>
              <ul><img src="assets/gfx/dilergo-about/14.png" width="250" alt=""></ul>
              <p>Po kliknięciu w interesujący nas ranking uczestnik widzi szczegóły osiągnięć takich jak np. pozycję w rankingu, lub przypis składki.</p>
              <ul><img src="assets/gfx/dilergo-about/15.png" width="250" alt=""></ul>
            </accordion-panel>
          </accordion>

          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" heading="Kasa">
              <ol class="manual-list">
                <li>
                  <p>W zakładce "Kasa" możemy zlecić wypłatę zebranych środków na własne konto bankowe.</p>
                  <img src="assets/gfx/dilergo-about/16.png" width="250" alt="">
                </li>
                <li>
                  <p>Po kliknięciu w przycisk "Wypłać środki" na adres e-mail użytkownika zostanie wysłany link umożliwiający dokończenie procesu wypłaty środków.</p>
                  <img src="assets/gfx/dilergo-about/17.png" width="250" alt="">
                </li>
                <li>Po kliknięciu w link użytkownik zostaje przeniesiony na stronę programu gdzie należy potwierdzić lub uzupełnić dane do rozliczenia i przelewu, a następnie potwierdzić wypłatę środków.</li>
              </ol>
            </accordion-panel>
          </accordion>
        </div>


          <!-- <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" [isOpen]="install" (isOpenChange)="install=$event" heading="Instalacja aplikacji na telefonie">
              <div class="container">
                <div class="row">
                  <div class="text-black">
                    <ol class="number-list">
                      <li>Jeżeli oglądasz tę stronę na swoim telefonie, aplikację możesz pobrać bezpośrednio z linków
                        poniżej:
                        <ul class="download-app list-group list-group-horizontal mb-0">
                          <li class="appstore mb-0 pb-0"><a [href]="AppStore" target="_blank">
                              <img src="assets/gfx/shops/appstore.svg" alt="Download on the App Store"></a></li>
                          <li class="google-play mb-0 pb-0"><a [href]="GooglePlay" target="_blank">
                              <img src="assets/gfx/shops/google-play.svg" alt="Get it on Google Play"></a></li>
                        </ul>
                      </li>

                      <li>Jeżeli oglądasz na swoim komputerze, to weź swój telefon i znajdź sklep:
                        <ol class="lower-aplha">
                          <li>Google Play (dla systemów Android)</li>
                          <li>App Store (dla iPhone'ów z systemem iOS).</li>
                        </ol>

                      </li>

                      <li>W sklepie wyszukaj: ERGO Hestia DILER GO!</li>
                      <div class="container">
                        <div class="row">
                          <div class="col-12 col-lg-5 text-center">
                            <img src="assets/gfx/tutorial/temp/image2-32_google.png" class="img-fluid" alt="">
                            <p>Przykład na Androida</p>
                          </div>
                          <div class="col-12 col-lg-5 text-center">
                            <img src="assets/gfx/tutorial/temp/image2-32_iphone.png" class="img-fluid" alt="">
                            <p>Przykład dla iPhone</p>
                          </div>
                        </div>
                      </div>
                      <li>Kliknij odpowiednio Zainstaluj (Android) lub Pobierz (iOS)</li>
                      <li>Następnie wprowadź swój login i hasło. Login i hasło jest takie samo jak przy logowaniu na
                        stronie www.ipunkt.ergohestia.pl</li>

                      <li><span class="text-red">Uwaga!</span> W przypadku telefonu iPhone: pojawi się ekran jak
                        poniżej.<br>
                        <img src="assets/gfx/tutorial/rq_prompt.png" class="mb-3 mt-3" width="250"><br>Kliknij „Pozwalaj”.
                      </li>
                      <li>Po poprawnym zalogowaniu zobaczysz swój ekran główny aplikacji.<br>
                        <img src="assets/gfx/tutorial/stronaglowna.png" width="250"></li>
                    </ol>
                    <div class="close-panel pointer-mouse"><a role="button" (click)="install=false;">Zwiń</a></div>

                  </div>
                </div>
              </div>
            </accordion-panel>
          </accordion>
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" [isOpen]="nawigacja" (isOpenChange)="nawigacja=$event" heading="Nawigacja w aplikacji">
              <div class="container">
                <div class="row">
                  <div class="col-10 offset-md-1 text-black">
                    <p>Poruszanie między sekcjami w aplikacji odbywa się za pomocą ikon umieszczonych na dole ekranu.<br>
                      <img src="assets/gfx/tutorial/temp/nawigacja.png" width="250" alt=""></p>
                    <p>Dodatkowe funkcjonalności znajdziesz w rozwijanym menu bocznym, tzw. "hamburgerze".<br>
                      <img src="assets/gfx/tutorial/temp/image6-40.png" width="250" alt=""></p>
                    <p>Po kliknięciu w "hamburgera" wysunie się menu dodatkowe: Ustawienia, Kontakt,
                      Regulamin, Mój profil oraz Wyloguj się.<br>
                      <img src="assets/gfx/tutorial/temp/image7-42.png" width="250" alt=""></p>
                    <p>W „Ustawieniach” znajdziesz 2 opcje do wyboru:</p>
                    <ol>
                      <li>Ciemny motyw aplikacji (włączona spowoduje, że cała aplikacja zmieni kolorystykę na ciemną).<br>
                        <img src="assets/gfx/tutorial/temp/image8-44.png" width="250" alt=""></li>
                      <li>Wyłączenie / włączenie Powiadomień PUSH. </li>
                    </ol>
                    <p>Rekomendujemy włączenie widomości PUSH. Będziesz otrzymywał na bieżąco informacje o zaliczonych
                      polisach spełniających warunki akcji, do której przystąpiłeś oraz o nowych akcjach przygotowanych
                      dla Ciebie. </p>
                    <div class="close-panel pointer-mouse"><a role="button" (click)="nawigacja=false;">Zwiń</a></div>
                  </div>
                </div>
              </div>
            </accordion-panel>
          </accordion>
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" [isOpen]="main" (isOpenChange)="main=$event" heading="Strona główna">
              <div class="container">
                <div class="row">
                  <div class="col-10 offset-md-1 text-black">
                    <img src="assets/gfx/tutorial/stronaglowna.png" width="250" alt="">
                    <p class="text-black">Strona główna podzielona jest na 3 sekcje.</p>

                    <ol class="number-list">
                      <li>Na górze podana jest ilość monet, jaką obecnie posiadasz do wykorzystania.</li>
                      <li>W centralnej części widoczne są wszystkie nowe lub trwające akcje specjalne. (Więcej w dziale
                        Akcje specjalne)</li>
                      <li>W dolnej sekcji znajdziesz najnowsze Wiadomości.</li>
                      <li>Klikając w Monety do wykorzystania, Akcje specjalne lub Wiadomości, przejdziesz bezpośrednio do
                        szczegółów tej sekcji. </li>
                    </ol>
                    <div class="close-panel pointer-mouse"><a role="button" (click)="main=false;">Zwiń</a></div>
                  </div>
                </div>
              </div>
            </accordion-panel>
          </accordion>
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" [isOpen]="specialActions" (isOpenChange)="specialActions=$event" heading="Akcje specjalne">
              <div class="container">
                <div class="row">
                  <div class="col-10 offset-md-1 text-black">
                    <ol class="number-list">
                      <li>Aby przejrzeć wszystkie akcje specjalne na stronie głównej możesz je przewijać palcem w lewo
                        &lt; – &gt; prawo.</li>
                      <li>Gdy pojawi się nowa Akcja specjalna na dole znajdziesz przycisk „Sprawdź”.<br>
                        <img src="assets/gfx/tutorial/sprawdz.png" width="250" alt=""></li>
                      <li> Po kliknięciu „Sprawdź” zobaczysz pełny opis wraz z warunkami i innymi informacjami.<br>
                        <img src="assets/gfx/tutorial/przystepujace.png" width="250" alt=""></li>
                      <li>Na dole opisu znajduję się przycisk „Przystępuję do akcji”. Klikając w niego, akceptujesz
                        warunki Akcji i automatycznie zaczynasz brać w niej udział.</li>
                      <li>Widok Akcja specjalnej, w której już bierzesz udział, pokazuje Twój aktualny progres.<br><img
                          src="assets/gfx/tutorial/akcjespecjlne.png" width="250" alt="">
                      </li>
                      <li>Klikając „Listę polis”, zobaczysz wszystkie dokumenty, które zaliczają się do tej akcji.<br><img
                          src="assets/gfx/tutorial/lista_polis.png" width="250" alt=""></li>
                    </ol>
                    <div class="close-panel pointer-mouse"><a role="button" (click)="specialActions=false;">Zwiń</a></div>
                  </div>
                </div>
              </div>
            </accordion-panel>
          </accordion>
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" [isOpen]="coins" (isOpenChange)="coins=$event" heading="Twoje monety">
              <div class="container">
                <div class="row">
                  <div class="col-10 offset-md-1 text-black">
                    <img src="assets/gfx/tutorial/twojemonety.png" width="250" alt="">
                    <ol class="number-list">
                      <li>Na górze sekcji znajdziesz podsumowanie zdobytych monet w podziale na:
                        <ol class="lower-aplha">
                          <li>„Rok”</li>
                          <li>„Miesiąc”</li>
                          <li>„Do wykorzystania”.</li>
                        </ol>
                      </li>
                      <li>Możesz przełączyć podsumowanie swoich aktywności na „Nagrody” lub „Wypłaty”.</li>
                      <li>Niżej znajduje się kalendarz. Przesuwając palcem „lewo &lt; – &gt; prawo”, możesz wybrać
                        interesujący Cię miesiąc.</li>
                      <li>Klikając w niego, podsumowanie automatycznie zliczy Twoje monety w wybranym miesiącu, a poniżej
                        wyświetli się lista wszystkich zaliczonych aktywności.</li>
                    </ol>
                    <div class="close-panel pointer-mouse"><a role="button" (click)="coins=false;">Zwiń</a></div>
                  </div>
                </div>
              </div>
            </accordion-panel>
          </accordion>
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" [isOpen]="messages" (isOpenChange)="messages=$event" heading="Wiadomości">
              <div class="container">
                <div class="row">
                  <div class="col-10 offset-md-1 text-black">
                    <img src="assets/gfx/tutorial/wiadomosci.png" width="250" alt="">
                    <ol class="number-list">
                      <li>W tej sekcji znajdują się wysłane do Ciebie wiadomości.</li>
                      <li>Wiadomości nieprzeczytane są oznaczone czerwoną kropką.</li>
                      <li>Po kliknięciu w wybraną wiadomość, w nowej karcie otworzy się jej pełna treść.</li>
                      <li>Między wiadomościami w pełnej wersji możesz przełączać się używając przycisków „Powrót” i
                        „Kolejna”.</li>
                    </ol>
                    <div class="close-panel pointer-mouse"><a role="button" (click)="messages=false;">Zwiń</a></div>
                  </div>
                </div>
              </div>
            </accordion-panel>
          </accordion>
          <accordion [isAnimated]="true">
            <accordion-panel class="panel-title" [isOpen]="card" (isOpenChange)="card=$event" heading="Twoja karta">
              <div class="container">
                <div class="row">
                  <div class="col-10 offset-md-1 text-black">
                    <img src="assets/gfx/tutorial/karta_start.png" width="250" alt="">
                    <ol class="number-list">
                      <li>Karta pre-paid jest dostępna od 100 zgromadzonych monet.</li>
                      <li>Aby ją otrzymać, po prostu wypłać środki z aplikacji i potwierdź chęć otrzymania karty.<br> <img
                          src="assets/gfx/tutorial/karta_p2.png" width="250" alt=""></li>
                      <li>Jeżeli nie masz jeszcze 100 monet, możesz wypłacić swoją nagrodę tradycyjnym przelewem. Wprowadź
                        kwotę do wypłaty i kliknij: „Wypłać tradycyjnym przelewem” lub zbieraj dalej do uzyskania 100
                        monet.<br>
                        <img src="assets/gfx/tutorial/karta_p3.png" width="250" alt=""></li>
                      <li>W każdym przypadku wypłaty (czy na kartę, czy przelewem tradycyjnym) dostaniesz e-mail na swoją
                        pocztowę z prośbĄ o potwierdzenie zamówienia wypłaty. Kliknij w link w e-mailu.</li>
                      <li>Zostaniesz przekierowany na stronę www.ipunkt.ergohestia.pl.</li>
                      <li>Zaloguj się i potwierdź dane do rozliczenia. Jest to warunek konieczny do realizacji wypłaty.
                        <br>
                        <img src="assets/gfx/tutorial/temp/image18-64.png" width="350" alt=""></li>
                      <li>Po kliknięciu "Potwierdź" wypłacone środki znajdą się na Twojej karcie lub odpowiednio koncie.
                        Staramy się zrobić to szybko. Jednak czasem może to potrwać 14 dni.</li>
                    </ol>
                    <div class="close-panel pointer-mouse"><a role="button" (click)="card=false;">Zwiń</a></div>
                  </div>
                </div>
              </div>
            </accordion-panel>
          </accordion> -->

      </div>
    <!-- </div>
  </div>
</div> -->
