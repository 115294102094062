import {createAction, props} from '@ngrx/store';
import { Profile, ChangePassword } from '../models/profile';


export const getProfile = createAction(
    '[Profile Page] get Profile'
);
export const resetSended = createAction(
    '[Profile Page] Reset Sended Data Profile'
);

export const updateProfile = createAction(
    '[Profile Page] Update Profile',
    props<{data:Profile}>()
);
export const changePassword = createAction(
    '[Profile Page] Change Password Profile',
    props<{credentials:ChangePassword}>()
);
export const getEpitState = createAction(
  '[Profile Page] get Epit State',
);
export const getEpit = createAction(
  '[Profile Page] get Epit',
);
export const getEpitAvailable = createAction(
  '[Profile Page] Get Epit Available',
);
