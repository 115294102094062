import {createFeatureSelector, createSelector, Action, combineReducers} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromConfirm from './confirm-page.reducer';

export const profileFeatureKey = 'confirm';

export interface ConfirmState {
    [fromConfirm.profilePageFeatureKey]: fromConfirm.State;
}

export interface State extends fromRoot.State {
    [profileFeatureKey]: ConfirmState;
}

export function reducers(state: ConfirmState | undefined, action: Action) {
    return combineReducers({
        [fromConfirm.profilePageFeatureKey]: fromConfirm.reducer
    })(state, action);
}

export const selectConfirmState = createFeatureSelector<State, ConfirmState>(profileFeatureKey);

export const selectConfirmPageState = createSelector(
    selectConfirmState,
    state => state.profilePage
);
export const selectPofile = createSelector(
    selectConfirmPageState,
    fromConfirm.selectProfile
);
export const selectError = createSelector(
    selectConfirmPageState,
    fromConfirm.selectError
);
export const selectRegisterPageValidationError = createSelector(
    selectConfirmPageState,
    fromConfirm.selectValidationError
);
export const selectSended = createSelector(
    selectConfirmPageState,
    fromConfirm.selectSended
);
export const selectStatus = createSelector(
    selectConfirmPageState,
    fromConfirm.selectStatus
);