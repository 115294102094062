
      <div class="col-12 col-md-10 offset-md-2 bg-white inner-content">

        <h3 class="page-title text-center">Mój profil</h3>

        <tabset #tabset type="pills" class="nav nav-pills justify-content-center">
          <tab heading="Dane do rozliczenia" class="tab-pane fade" [customClass]="tabset.tabs[0].active?'show':null">

            <!-- <div *ngIf="form.get('pitStatement').value">
              <div class="row">
                <div class="col-12">
                  <button type="button" class="btn btn-primary btn-epit mb-5" (click)="epitDownload()">Pobierz PIT-11</button>
                </div>
              </div>
            </div> -->
          <p class="section-description">Dane do rozliczenia</p>
          <!-- "pierwsze wprowadzanie: {{firstTime}}" -->
          <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="form-check">
              <input #company class="form-check-input radio" type="checkbox" id="statement_b"
                [ngClass]="{'ng-invalid ng-touched': income == undefined && form.dirty}"
                (change)="changeIncome('company',$event, another)">
              <label class="form-check-label" for="statement_b">
                wartość nagrody będzie stanowiła dla mnie przychód z prowadzonej przeze mnie działalności
                gospodarczej
              </label>
            </div>
            <div class="form-check">
              <input #another class="form-check-input radio" type="checkbox" id="statement_a"
                [ngClass]="{'ng-invalid ng-touched': income == undefined && form.dirty}"
                (change)="changeIncome('another',$event, company)">
              <label class="form-check-label" for="statement_a">
                wartość nagrody będzie stanowiła dla mnie przychód z innych źródeł
              </label>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <input type="text" class="form-control" id="name" placeholder="Imię" formControlName="name" required>
              </div>
              <div class="form-group col-6">
                <input type="text" class="form-control" id="surname" placeholder="Nazwisko" formControlName="surname"
                  required>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <input type="tel" class="form-control" placeholder="Numer telefonu"
                  [ngClass]="{'is-invalid': false && false}" prefix="+48" mask="000000000" formControlName="phone"
                  required>
              </div>
              <div class="form-group col-6">
                <input type="email" class="form-control" placeholder="Adres e-mail" formControlName="email" required>
              </div>
            </div>
            <div #restForm
              [@slideInOut]="{value: income == 'another' ?'in':'out', params:{pixels:'600px', time:'200ms'}}"
              [ngClass]="{'pointer-none': income == 'company'}">
              <div class="form-row">
                <div class="form-group col-md-4 col-5">
                  <input type="text" class="form-control" mask="00-000" placeholder="Kod pocztowy"
                    formControlName="zipCode" required>
                </div>
                <div class="form-group col-md-8 col-7">
                  <input type="text" class="form-control" placeholder="Miejscowość" formControlName="place" required>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 col-5">
                  <input type="text" class="form-control" placeholder="Ulica" formControlName="street" required>
                </div>
                <div class="form-group col-md-3 col-4">
                  <input type="text" class="form-control" placeholder="Nr domu" formControlName="houseNumber" required>
                </div>
                <div class="form-group col-md-3 col-3">
                  <input type="text" class="form-control" placeholder="Lokal" formControlName="apartmentNumber"
                    required>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <input type="text" class="form-control" placeholder="Gmina" formControlName="community" required>
                </div>
                <div class="form-group col-md-4">
                  <input type="text" class="form-control" placeholder="Powiat" formControlName="district" required>
                </div>
                <div class="form-group col-md-4">
                  <input type="text" class="form-control" placeholder="Poczta" formControlName="post" required>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <input type="text" class="form-control" placeholder="Województwo" formControlName="voivodeship"
                    [matAutocomplete]="autoVoivodeships" required>
                  <mat-autocomplete #autoVoivodeships="matAutocomplete">
                    <mat-option *ngFor="let voivodeship of filteredVoivodeships | async" [value]="voivodeship">
                      {{voivodeship}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="form-group col-md-6">
                  <input type="text" class="form-control" placeholder="Urząd Skarbowy" formControlName="taxOffice"
                    [matAutocomplete]="autoTaxOffices" required>
                  <mat-autocomplete #autoTaxOffices="matAutocomplete">
                    <mat-option *ngFor="let taxOffice of filteredTaxOffices | async" [value]="taxOffice">
                      {{taxOffice}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox" id="statement_e"
                formControlName="pitStatement" [value]="false">
              <label class="form-check-label" for="statement_e">
                Wyrażam zgodę na przesyłanie przez Sopockie Towarzystwo Ubezpieczeń ERGO Hestia S.A. informacji PIT-11 drogą elektroniczną (Informacje PIT-11 otrzymają tylko i wyłącznie użytkownicy, którzy podczas rejestracji do Programu Motywacyjnego DILER GO! złożyli oświadczenie zgodnie, z którym wartość nagród będzie stanowiła dla nich przychód z innych źródeł)*.</label>
            </div>
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox" id="statement_c"
                formControlName="billingStatement" [value]="false" required>
              <label class="form-check-label" for="statement_c">
                Zobowiązuje się do niezwłocznej aktualizacji powyższych danych na koncie uczestnika programu
                ERGO Hestia DILER GO!
                jeśli ulegną zmianie. </label>
            </div>
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox" id="statement_d" formControlName="statement"
                [value]="false">
              <label class="form-check-label" for="statement_d">Wyrażam zgodę na przetwarzanie moich danych osobowych
                przez Sopockie Towarzystwo Ubezpieczeń ERGO Hestia S.A. w celu otrzymywania informacji marketingowych z&nbsp;wykorzystaniem środków
                porozumiewania się na odległość (telefon, e-mail, indywidualne Konto Uczestnika udostępnione
                w&nbsp;Aplikacji Programu), przy użyciu podanych przeze mnie danych kontaktowych.</label>
            </div>

        <p class="section-description">Numer konta bankowego</p>
        <div class="form-row">
          <div class="form-group col-12">
            <input type="text" class="form-control" placeholder="Numer konta bankowego"
              mask="00 0000 0000 0000 0000 0000 0000" formControlName="account" required>
          </div>
        </div>

        <p class="section-description">Adres do wysyłki nagród</p>
        <div class="form-row">
          <div class="form-group col-md-4 col-5">
            <input type="text" class="form-control" mask="00-000" placeholder="Kod pocztowy"
              formControlName="deliveryZipCode" required>
          </div>
          <div class="form-group col-md-8 col-7">
            <input type="text" class="form-control" placeholder="Miejscowość" formControlName="deliveryPlace" required>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 col-5">
            <input type="text" class="form-control" placeholder="Ulica" formControlName="deliveryStreet" required>
          </div>
          <div class="form-group col-md-3 col-4">
            <input type="text" class="form-control" placeholder="Nr domu" formControlName="deliveryHouseNumber"
              required>
          </div>
          <div class="form-group col-md-3 col-3">
            <input type="text" class="form-control" placeholder="Lokal" formControlName="deliveryApartmentNumber"
              required>
          </div>
        </div>


          <div class="row p-0">
            <div class="col-12 mb-4">
              <div class="alert alert-danger" role="alert" *ngIf="form.invalid && form.dirty">Wymagane pola nie zostały uzupełnione</div>
            </div>
          </div>
            <div class="row">
              <div class="col-md-6 col-sm-12" *ngIf="edit">
                <button type="button" (click)="toggleEdit()" class="btn btn-secondary btn-block">Anuluj</button>
              </div>
              <div class="col-md-6 col-sm-12" [ngClass]="{'offset-md-6': !edit}">
                <button type="button" (click)="submit()" class="btn btn-block"
                  [ngClass]="{'btn-dark': edit, 'btn-primary':!edit}"
                  [innerText]="edit?'Zapisz':'Edytuj'">Edytuj</button>
              </div>
            </div>
          </form>
          <div class="row mt-3" *ngIf="sended && showDataUpdate">
            <div class="col-12">
              <p style="color:#28a745">Twoje dane zostały zaktualizowane</p>
            </div>
          </div>
          <div class="row mt-3" *ngIf="error">
            <div class="col-12">
              <p style="color:red">Wystąpił błąd, spróbuj jeszcze raz</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <p class="font-12">*W przypadku uczestników Programu Motywacyjnego DILER GO!, którzy w roku 2020 otrzymali także nagrody w zakończonym już Programie Motywacyjnym Moje Punkty, informacja PIT-11 obejmie również przychody z nagród otrzymanych w Programie Motywacyjnym Moje Punkty stanowiące dla nich przychody z innych źródeł.</p>
            </div>
          </div>

        </tab>


        <tab heading="Zmiana hasła" class="tab-pane fade" [customClass]="tabset.tabs[1].active?'show mw-140':'mw-140'">

          <div class="alert alert-secondary" role="alert" *ngIf="passSended">
            <p>Twoje hasło zostało zaktualizowane!</p>
          </div>


<div class="container">

          <div class="row justify-content-center">
              <div class="col-12 col-md-6">

              <form [formGroup]="password" (ngSubmit)="submitChangePassword()">

                <div class="form-group col">
                  <input type="password" class="form-control" id="inputname" placeholder="Twoje obecne hasło"
                    [ngClass]="{'is-invalid': validationError && validationError.oldPassword}"
                    formControlName="oldPassword" required>
                </div>

                <div class="form-group col">
                  <input type="password" class="form-control" id="inputsurname" placeholder="Wpisz nowe hasło"
                    [ngClass]="{'is-invalid': validationError && validationError.newPassword}"
                    formControlName="newPassword" required>
                </div>
                <div class="form-group col">
                  <input type="password" class="form-control" id="peselnumber" placeholder="Powtórz nowe hasło"
                    [ngClass]="{'is-invalid': validationError && validationError.confirmPassword}"
                    formControlName="confirmPassword" required>
                </div>

            <div class="row">
              <div class="col">
                <button type="submit" class="btn btn-primary btn-block">Zmień</button>
              </div>
            </div>

          </form>

          </div>
          </div>
          </div>

        </tab>
      </tabset>

      </div>

<!-- [customClass]="tabset.tabs[0].active?'show':null" -->
<!-- [@fadeAnimation]="tabset.tabs[1].active ? 'in' : 'out'" -->
