import {FormGroup, FormControl, AbstractControl, ValidatorFn} from '@angular/forms';

export class Utils {

    public static validateFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl && !control.valid) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                Utils.validateFormFields(control);
            }
        });
    }
    public static autocompleteStringValidator(validOptions: Array<string>): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          if (validOptions.indexOf(control.value) !== -1) {
            return null  /* valid option selected */
          }
          return { 'invalidAutocompleteString': { value: control.value } }
        }
      }
}