import {Component, OnDestroy} from '@angular/core';
import {Store, select} from '@ngrx/store';
import * as fromConfirm from '../reducers';
import { ConfirmPageActions } from '../actions';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Profile } from 'src/app/profile/models/profile';

@Component({
    template: `
      <app-confirm 
        [error]="error$"
        [pofile]="pofile$ | async"
        [accesToken]="accesToken"
        [sended]="sended$ | async"
        [status]="status$ | async"
        (submitted)="onPofileSubmitted($event)"
        (confirm)="onConfirm($event)"
        >
      </app-confirm>`
})
export class ConfirmPageComponent implements OnDestroy{
    error$ = this.store.pipe(select(fromConfirm.selectError));
    pofile$ = this.store.pipe(select(fromConfirm.selectPofile));
    validationError$ = this.store.pipe(select(fromConfirm.selectRegisterPageValidationError));
    sended$ = this.store.pipe(select(fromConfirm.selectSended));
    status$ = this.store.pipe(select(fromConfirm.selectStatus));
    
    private sub: Subscription;
    accesToken: string;

    constructor(private store: Store<fromConfirm.State>, private route: ActivatedRoute) {
        this.store.dispatch(ConfirmPageActions.getProfile());
        this.store.dispatch(ConfirmPageActions.CheckLinkUser());
    }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.accesToken = params['accesToken'];
            if(this.accesToken) this.store.dispatch(ConfirmPageActions.setToken({ accesToken: this.accesToken }));
        });
    }
    onPofileSubmitted(data:Profile){
        let tmpData = {...data};
        Object.entries(tmpData).forEach(e => {
            if(e[1] === null || e[1]==="")
                delete tmpData[e[0]];
        });
        this.store.dispatch(ConfirmPageActions.updateProfile({data:tmpData}));
    }
    ngOnDestroy(): void {
        this.store.dispatch(ConfirmPageActions.resetSended());
        if(this.sub) this.sub.unsubscribe()
    }
    onConfirm(account:string){
        if(account){
            console.log(account);
            this.store.dispatch(ConfirmPageActions.confirmAccount({account}));
        }else
            this.store.dispatch(ConfirmPageActions.confirm());
    }
}
