import {createFeatureSelector, createSelector, Action, combineReducers} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromRegister from './register-page.reducer';

export const registerFeatureKey = 'register';

export interface RegisterState {
    [fromRegister.registerPageFeatureKey]: fromRegister.State;
}

export interface State extends fromRoot.State {
    [registerFeatureKey]: RegisterState;
}

export function reducers(state: RegisterState | undefined, action: Action) {
    return combineReducers({
        [fromRegister.registerPageFeatureKey]: fromRegister.reducer
    })(state, action);
}

export const selectRegisterState = createFeatureSelector<State, RegisterState>(registerFeatureKey);

export const selectRegisterPageState = createSelector(
    selectRegisterState,
    state => state.registerPage
);

export const selectRegisterPageValidationError = createSelector(
    selectRegisterPageState,
    fromRegister.selectValidationError
);
export const selectRegisterError = createSelector(
    selectRegisterPageState,
    fromRegister.selectError
);
export const selectUser = createSelector(
    selectRegisterPageState,
    fromRegister.selectUser
);
export const selectToken = createSelector(
    selectRegisterPageState,
    fromRegister.selectToken
);