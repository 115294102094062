import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType, createEffect} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap, withLatestFrom, delay, switchMap} from 'rxjs/operators';
import {ResetPasswordPageActions, ResetPasswordApiActions} from '../actions';
import {ResetPasswordService} from '../services/reset-password.service';
import {Verification, Password} from '../models/reset-password';
import {Store, select} from '@ngrx/store';
import * as fromResetPassword from '../reducers';
import * as fromRoot from '../../reducers';
import {errorType, APIErrorResponse} from 'src/app/common/http/response';
import { LoginPageActions } from 'src/app/auth/actions';

@Injectable()
export class ResetPasswordEffects {
    confirmUser$ = createEffect(() => this.actions$.pipe(
        ofType(ResetPasswordPageActions.confirmUser),
        map((action=>action.accesToken)),
        exhaustMap((accesToken: string) =>
            this.resetPasswordService.confirmUser(accesToken).pipe(
                map((email:string) => ResetPasswordApiActions.confirmUserSuccess({email, accesToken})),
                catchError((error: APIErrorResponse) => 
                       of(ResetPasswordApiActions.confirmUserFailure({
                           error
                       }))
                ))
        )
    ));
    setPassword$ = createEffect(() => this.actions$.pipe(
        ofType(ResetPasswordPageActions.setPassword),
        withLatestFrom(this.store.select(fromResetPassword.selectUser), this.store.select(fromResetPassword.selectToken)),
        map(action=> {return { accesToken: (action[1] as any).accesToken, email:action[1].email, password: action[0].password, confirmPassword: action[0].confirmPassword  } as Password}),
        exhaustMap((password: Password) =>
            this.resetPasswordService.setPassword(password).pipe(
                map((res:boolean) => 
                    res? ResetPasswordApiActions.setPasswordSuccess():
                    ResetPasswordApiActions.setPasswordFailure(null)
                ),
                catchError((error: errorType) => {
                    // console.log(error);
                    if (error.validationError) {
                        return of(ResetPasswordApiActions.validationFailure({
                            error: JSON.parse(error.validationError)
                        }))
                    } else if (error.requestError) {
                       ResetPasswordApiActions.setPasswordFailure(null)
                    }
                })
            )
        )
    ));

    setFirstLogin$ = createEffect(() => this.actions$.pipe(
        ofType(ResetPasswordApiActions.setPasswordSuccess),
        map(()=>LoginPageActions.setFirst())
    ));
    sendLink$ = createEffect(() => this.actions$.pipe(
        ofType(ResetPasswordPageActions.sendLink),
        map(action=>action.email),
        switchMap((email: string) =>
            this.resetPasswordService.sendLink(email).pipe(
                map((res:boolean) => 
                    res? ResetPasswordApiActions.sendLinkSuccess():
                    ResetPasswordApiActions.sendLinkFailure({error:'błąd!'})
                ),
                catchError((error: string) => 
                       of(ResetPasswordApiActions.sendLinkFailure({error}))
                )
            )
        )
    ));
    sendResetLink$ = createEffect(() => this.actions$.pipe(
        ofType(ResetPasswordPageActions.sendResetLink),
        map(action=>action.email),
        switchMap((email: string) =>
            this.resetPasswordService.sendResetLink(email).pipe(
                map((res:boolean) => 
                    res? ResetPasswordApiActions.sendResetLinkSuccess():
                    ResetPasswordApiActions.sendResetLinkFailure({error:'błąd!'})
                ),
                catchError((error: string) => 
                       of(ResetPasswordApiActions.sendResetLinkFailure({error}))
                )
            )
        )
    ));
    
    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private resetPasswordService: ResetPasswordService,
        private router: Router
    ) {}
}