import {createAction, props} from '@ngrx/store';
import {Credentials} from '../models/auth';

export const login = createAction(
    '[Login Page] Login',
    props<Credentials>()
);

export const changePassword = createAction(
    '[Login Page] Change Password',
    props<{credentials: Credentials, resetPassword: boolean}>()
);


export const setFirst = createAction(
    '[Login Page] Set First Time Login'
);
export const cleanFirst = createAction(
    '[Login Page] Clean First'
);
