import {trigger, state, style, transition, animate, group} from '@angular/animations';

export const SlideInOutAnimation = [
    trigger('slideInOut', [
        state('in', style({
            'max-height': '{{pixels}}', 
            opacity: '1',
            'pointer-events' : 'auto'
        }),
        { params: { pixels: '72px', time:'600ms'}}),
        state('out', style({
            'max-height': '0px', 
            opacity: '0',
            'pointer-events' : 'none'
        }),
        { params: { pixels: '72px', time:'600ms'}}),
        transition('in => out', [group([
            animate('{{time}} ease-in-out', style({
                'max-height': '0px',
                'opacity': '0'
            }))
        ]
        )]),
        transition('out => in', [group([
            animate('{{time}} ease-in-out', style({
                'max-height': '{{pixels}}',
                'opacity': '1'
            }))
        ]
        )])
    ]),
];
