import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {apiUrl} from 'src/app/common/variables';
import { Observable, throwError } from 'rxjs';
import { APIErrorResponse } from 'src/app/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WithdrawalService {
    constructor(private http: HttpClient) {}

    public checkLink(token:string): Observable<boolean> {
        const params = new HttpParams()
        .set('token', token)
        .set('grant_type', 'basic');
        
        return this.http.post<boolean>(apiUrl + 'coins/verification-link', params)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError((error: APIErrorResponse) => {
                return throwError((error.error as any).error_description);
            })
        );
    }
}
