import {
    ActionReducerMap,
    ActionReducer,
    MetaReducer,
    Action,
    createSelector,
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import {localStorageSync} from 'ngrx-store-localstorage'

import {InjectionToken} from '@angular/core';

export interface State {
    router: fromRouter.RouterReducerState<any>;
}

export const ROOT_REDUCERS = new InjectionToken<
    ActionReducerMap<State, Action>
>('Root reducers token', {
    factory: () => ({
        router: fromRouter.routerReducer,
    }),
});

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const result = reducer(state, action);
        console.groupCollapsed(action.type);
        console.log('prev state', state);
        console.log('action', action);
        console.log('next state', result);
        console.groupEnd();
        return result;
    };
}

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
    return localStorageSync({keys: ['auth', 'profile'], rehydrate: true})(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger, localStorageSyncReducer]
    : [localStorageSyncReducer];

export const getRouterState = (state: State) => state.router;

export const getCurrentUrl = createSelector(
    getRouterState,
    (state: fromRouter.RouterReducerState) => state && state.state && state.state.url.split('/')[1]
);
export const getCurrentUrlToken = createSelector(
    getRouterState,
    (state: fromRouter.RouterReducerState) => state && state.state && state.state.url.split('/')[2]
);
