import { Input, Output, EventEmitter, Component, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { Register, Password } from '../models/register';
import { FormGroup, FormControl } from '@angular/forms';
import { Utils } from '../../common/utils';

@Component({
  selector: 'app-password-form',
  template: `
  <div class="container mb-5">
    <div class="row">
        <div class="col-md-8 offset-md-2 col-12" *ngIf="user && user.email; else badLink">
          <h3 class="pt-100-lg text-red font-weight-bold">Super!</h3>
          <h3 class="pb-5">Twoje konto jest już aktywne, Twoim loginem jest adres <span
              style="white-space: nowrap;">e-mail.</span> Teraz ustal hasło do konta</h3>
          <div class="container">
            <div class="row">
              <div class="col-md-10 offset-md-1 col-12">
                <form [formGroup]="form" (ngSubmit)="submit()">
                  <div class="row">
                    <div class="col-md-8 offset-md-2 col-12">
                      <div class="form-group">
                        <input type="email" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.email}" placeholder="twój@adres.pl" formControlName="email" required>
                      </div>
                      <div class="form-group">
                        <input type="password" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.password}" placeholder="Wprowadź hasło" formControlName="password" required>
                      </div>
                      <div class="form-group mb-5">
                        <input type="password" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.confirmPassword}" placeholder="Powtórz hasło" formControlName="confirmPassword" required>
                      </div>
                      <p>
                        <small>
                          <ul class="list-unstyled">
                            <li>Hasło powinno zawierać:</li>
                            <li>- minimum 8 znaków</li>
                            <li>- co najmniej 1 wielka litera</li>
                            <li>- co najmniej 1 mała litera</li>
                            <li>- co najmniej 1 cyfra</li>
                            <li>- co najmniej 1 znak specjalny</li>
                          </ul>
                        </small></p>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary btn-block">Zapisz</button>
                </form>
              </div>
            </div>

          </div>
        </div>
        <ng-template #badLink>
        <div class="col-md-8 offset-md-2 col-12">
          <h3 class="pt-100-lg text-red font-weight-bold">Link nieaktywny!</h3>
        </div>
        </ng-template>
      </div>
    </div>`
})

export class PasswordFormComponent implements OnInit, OnChanges {
  company: boolean = false;
  @Input() validationError: Register | null;
  @Input() user: Register | null;
  @Input() error: string | null;
  @Output() submitted = new EventEmitter<Register>();

  constructor() { }


  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl('')
  });
  ngOnInit(): void {
    this.form.get('email').disable();
    this.validationError = null;
    this.error = null;
  }
  ngOnChanges(changes: SimpleChanges): void {
    let simpleChange = changes['user'];
    if (simpleChange && !simpleChange.firstChange && this.user) {
      this.form.get('email').setValue(this.user.email);
    }
  }
  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
    else {
      Utils.validateFormFields(this.form);
    }
  }
}
