import {NgModule} from '@angular/core';
import {MenuComponent} from './components/menu.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {TopNavComponent} from './components/topnav.component';

export const COMPONENTS = [
    MenuComponent,
    TopNavComponent
];

@NgModule({
    imports: [
        RouterModule,
        CommonModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class CoreModule {}
