import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WithdrawalPageComponent} from './containers';

const routes: Routes = [{path: 'withdrawal/:tokenId', component: WithdrawalPageComponent} ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WithdrawalRoutingModule {}
