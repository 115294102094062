import { Input, Output, EventEmitter, Component, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { ResetPassword, Password } from '../models/reset-password';
import { FormGroup, FormControl } from '@angular/forms';
import { Utils } from '../../common/utils';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-send-link',
  template: `

      <div class="col bg-white inner-content">
        <h4 class="pb-5">Jeśli nie możesz odnaleźć wiadomości e-mail wraz z linkiem aktywacyjnym, możesz wygenerować nowy link wpisując swój adres e-mail podany podczas rejestracji.</h4>
            
        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 pt-5">

            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="form-group">
                <input type="email" class="form-control" [ngClass]="{'is-invalid': validationError && validationError.email}" placeholder="twój@adres.pl" formControlName="email" required>
              </div>
              
              <button type="submit" class="btn btn-primary btn-block">Wyślij ponownie link aktywacyjny</button>
            </form>

          </div>
        </div>
      </div>

        <div class="col-md-10 offset-md-1 col-12">
          <div *ngIf="sended" class=" text-center mt-4 hint-descr text-center">
            <p>Link aktywacyjny został wysłany na Twój adres e-mail.</p>
          </div>

          <div *ngIf="error" class="hint-descr text-center mt-4 red">
            <span *ngIf="error.requestError=='user_does_not_exists'">Podany adres nie był użyty podczas rejestracji. To może oznaczać, że wprowadzony adres jest błędny lub proces rejestracji nie został ukończony.</span>
            <span *ngIf="error.requestError!='user_does_not_exists'">Wystąpił błąd, spróbuj ponownie za jakiś czas</span>
          </div>

        </div>`
})

export class SendLinkFormComponent implements OnInit {
  company: boolean = false;
  @Input() validationError: ResetPassword | null;
  @Input() sended: boolean | null;
  @Input() error: any | null;
  @Output() submitted = new EventEmitter<ResetPassword>();

  constructor() { }


  form: FormGroup = new FormGroup({
    email: new FormControl('', RxwebValidators.email())
  });
  ngOnInit(): void {
    this.validationError = null;
    this.error = null;
  }
  ngOnChanges(changes: SimpleChanges): void {
    let simpleChange = changes['sended'];
    if(simpleChange){
      if(this.sended){
        this.form.disable();
      }
    }
  }
  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
    else {
      Utils.validateFormFields(this.form);
    }
  }
}
