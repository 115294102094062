import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {tap, switchMap, map, delay, catchError, exhaustMap} from 'rxjs/operators';
import { ProfilePageActions, ProfileApiActions } from '../actions';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { ProfileService } from '../service';
import { Profile, ChangePassword } from '../models/profile';
import { errorType } from 'src/app/common/http/response';

@Injectable()
export class ProfileEffects {

    getProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilePageActions.getProfile),
            switchMap(() =>
                this.profileService.getProfile().pipe(
                    map((data: Profile) =>
                        ProfileApiActions.getProfileSuccess({ data }),
                    ),
                    catchError((error: string) =>
                        of(ProfileApiActions.getProfileFailure({ error })),
                    )
                )
            )
        )
    );
    updateProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilePageActions.updateProfile),
            map(action=>action.data),
            switchMap((data:Profile) =>
                this.profileService.updateProfile(data).pipe(
                    map((res: boolean) =>{
                        if(res){
                            return ProfileApiActions.updateProfileSuccess({ data })
                        }else{
                            return ProfileApiActions.updateProfileFailure({ error:'błąd' })
                        }
                    }),
                    catchError((error: string) =>
                        of(ProfileApiActions.updateProfileFailure({ error })),
                    )
                )
            )
        )
    );
    updateProfileSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfileApiActions.updateProfileSuccess),
            map(()=>ProfilePageActions.getProfile())
        )
    );
    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilePageActions.changePassword),
            map(action=>action.credentials),
            switchMap((credentials:ChangePassword) =>
                this.profileService.changePassword(credentials).pipe(
                    map((res: boolean) =>{
                        if(res){
                            return ProfileApiActions.changePasswordSuccess()
                        }else{
                            return ProfileApiActions.changePasswordFailure({ error:'błąd' })
                        }
                    }),
                    catchError((error: errorType) => {
                        if (error.validationError) {
                            return of(ProfileApiActions.validationFailure({
                                error: JSON.parse(error.validationError)
                            }))
                        } else if (error.requestError == "old_password_do_not_match") {
                            return of(ProfileApiActions.validationFailure({
                                error: {oldPassword:true} }))
                        } else {
                            ProfileApiActions.changePasswordFailure({ error:'błąd' })
                        }
                    })
                )
            )
        )
    );

  getEpit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePageActions.getEpit),
      exhaustMap(() =>
        this.profileService.getEpit().pipe(
          map((data) =>
            ProfileApiActions.getEpitSuccess(),
          ),
          catchError((error: string) =>
            of(ProfileApiActions.getEpitFailure({ error:"bład" })),
          )
        )
      )
    )
  );

  getEpitAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePageActions.getEpitAvailable),
      switchMap(() =>
        this.profileService.getEpitAvailable().pipe(
          map((epitAvailable: boolean) =>
            ProfileApiActions.getEpitAvailableSuccess({epitAvailable}),
          ),
          catchError((error: string) =>
            of(ProfileApiActions.getEpitAvailableFailure({error})),
          )
        )
      )
    )
  );

    constructor(private actions$: Actions, private router:Router, private profileService:ProfileService) { }
}
