import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegisterRoutingModule} from './register-routing.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {RegisterEffects} from './effects';
import * as fromRegister from '../register/reducers';
import {ReactiveFormsModule} from '@angular/forms';
import {PasswordPageComponent, VerificationPageComponent, AfterRegisterPageComponent} from './containers';
import {PasswordFormComponent} from './components/password-form.component';
import {VerificationFormComponent} from './components/verification-form.component';
import {NgxMaskModule} from 'ngx-mask';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';
import { AfterRegisterComponent } from './components/after-register.component';

export const COMPONENTS = [
    PasswordPageComponent,
    PasswordFormComponent,
    VerificationPageComponent,
    VerificationFormComponent,
    AfterRegisterPageComponent,
    AfterRegisterComponent
];

@NgModule({
    imports: [
        CommonModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        RegisterRoutingModule,
        NgxMaskModule.forRoot(),
        StoreModule.forFeature(fromRegister.registerFeatureKey, fromRegister.reducers),
        EffectsModule.forFeature([RegisterEffects])
    ],
    providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6Le9c8wZAAAAAMZeKvo_xpCFKq7Kf0MyIxyUMnIe' } as RecaptchaSettings,
    },
  ],
    declarations: COMPONENTS
})
export class RegisterModule {}
