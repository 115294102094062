import {AuthApiActions, LoginPageActions} from '../actions';
import {createReducer, on} from '@ngrx/store';

export const loginPageFeatureKey = 'loginPage';

export interface State {
    error: string | null;
    first: boolean | null;
}

export const initialState: State = {
    error: null,
    first: null
};

export const reducer = createReducer(
    initialState,
    on(
        LoginPageActions.login,
        AuthApiActions.loginSuccess,
        (state) => ({...state, error: null, first:false})),
    on(
        AuthApiActions.loginFailure,
        AuthApiActions.changePasswordFailure,
        (state, {error}) => ({...state, error})
    ),
    on(LoginPageActions.setFirst,
        (state)=>({...state, first:true})),
    on(LoginPageActions.cleanFirst,
        (state)=>({...state, first:false}))
);

export const selectError = (state: State) => state.error;
export const selectFirst = (state: State) => state.first;
