import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {HttpClient, HttpParams} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';
import {Verification, Password} from '../models/register';
import {apiUrl} from 'src/app/common/variables';
import { APIErrorResponse } from 'src/app/common/http';
import { Token } from 'src/app/auth/models';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    constructor(private http: HttpClient) {}

    verification(verification: Verification): Observable<string> {
        let params = new HttpParams()
            .set('name', verification.name)
            .set('surname', verification.surname)
            .set('pesel', verification.pesel)
            .set('email', verification.email)
            .set('confirmEmail', verification.confirmEmail)
            .set('phone', verification.phone)
            .set('statement', verification.statement.toString())
            .set('recaptcha', verification.recaptcha)
            .set('grant_type', 'basic');
        return this.http.post<string>(apiUrl + 'user/register', params)
            .pipe(
                map((res: string) => {
                    // console.log(res);
                    
                    return res;
                }),
                catchError((error: APIErrorResponse) => {
                    return throwError(error.error.type);
                })
            );
    }
    
    confirmUser(accesToken: string):Observable<string>{
        let params = new HttpParams()
            .set('token', accesToken)
            .set('grant_type', 'basic');
        return this.http.post<string>(apiUrl + 'user/confirm', params)
            .pipe(
                map((res: string) => {
                    return res;//email
                }),
                catchError((error: APIErrorResponse) => {
                    return throwError(error);
                })
            );
    }
    setPassword(password:Password):Observable<boolean>{
        let params = new HttpParams()
            .set('token', password.accesToken)
            .set('email', password.email)
            .set('password', password.password)
            .set('confirmPassword', password.confirmPassword);
        return this.http.post<boolean>(apiUrl + 'user/password', params)
            .pipe(
                map((res: boolean) => {
                    return res;
                }),
                catchError((error: APIErrorResponse) => {
                    return throwError(error.error.type);
                })
            );
    }
}
