import {Component, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import * as fromWithdrawal from '../reducers';
import { WithdrawalPageActions } from '../actions';

@Component({
    template: `
      <app-withdrawal [error]="error$ | async">
      </app-withdrawal>`
})
export class WithdrawalPageComponent implements OnInit{
    error$ = this.store.pipe(select(fromWithdrawal.selectError));
    constructor(private store: Store<fromWithdrawal.State>) {}
    ngOnInit(): void {
        this.store.dispatch(WithdrawalPageActions.CheckLink());
    }
}
