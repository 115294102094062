import {Injectable} from '@angular/core';
import {Observable, throwError, of} from 'rxjs';
import {Credentials, Token} from '../models/auth';
import {HttpClient, HttpParams, HttpErrorResponse} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';
import {apiUrl} from '../../common/variables';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpClient) {}

    public login(credentials: Credentials): Observable<Token> {
        const params = new HttpParams()
            .set('username', credentials.email)
            .set('password', credentials.password)
            .set('grant_type', 'password');
        return this.post(params);
        // return of({access_token: '123', refresh_token: '321'})
    }

    public clientCredentialsToken(): Observable<Token> {
        const params = new HttpParams()
            .set('grant_type', 'client_credentials');
        return this.post(params);
    }

    public refreshToken(token: Token): Observable<Token> {
        const params = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', token.refresh_token + '|' + token.access_token);
        return this.post(params);
    }

    public changePassword(credentials: Credentials, resetPassword: boolean): Observable<boolean> {
        const params = new HttpParams()
            .set('oldPassword', credentials.password)
            .set('newPassword', credentials.newPassword)
            .set('confirmPassword', credentials.confirmPassword)
            .set('resetPassword', resetPassword.toString());
        return this.http.post<boolean>(apiUrl + 'user/change-password', params)
            .pipe(
                map((success: boolean) => {
                    return success;
                }),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error.error);
                })
            );
    }

    private post(params: HttpParams): Observable<Token> {
        return this.http.post<Token>(apiUrl + 'oauth/token', params)
            .pipe(
                map((token: Token) => {
                    return token;
                }),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error.error);
                })
            );
    }

    public basicAuthorization(): string {
        return 'Basic bHA6d2ozanlxV1BnYkRIcjR4T2Y2ZklCVWk0dnpFZjYwa3E';
    }
}
