import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {LoginPageComponent} from './containers';
import {LoginFormComponent} from './components';

import {AuthEffects} from './effects';
import * as fromAuth from './reducers';

import {AuthRoutingModule} from './auth-routing.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './services/token.interceptor';

export const COMPONENTS = [
    LoginPageComponent,
    LoginFormComponent
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AuthRoutingModule,
        StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
        EffectsModule.forFeature([AuthEffects])
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    declarations: COMPONENTS
})
export class AuthModule {}
