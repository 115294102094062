import {Injectable} from '@angular/core';
import {Observable, throwError, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';
import { Password} from '../models/reset-password';
import {apiUrl} from 'src/app/common/variables';
import { APIErrorResponse } from 'src/app/common/http';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {
    constructor(private http: HttpClient) {}
    
   //http://localhost:4200/password/jSim4MGk2d6P9r1dE40ClX6Fe9L_HV_sI-347MVhihV3lUr_ksuofAShVMrqw-HEnJVUrZtlsPL-s3oNdMLVSg
    confirmUser(accesToken: string):Observable<string>{
        let params = new HttpParams()
            .set('token', accesToken)
            .set('grant_type', 'basic');
        return this.http.post<string>(apiUrl + 'user/reset-password-link', params)
            .pipe(
                map((res: string) => {
                    return res;//email
                }),
                catchError((error: APIErrorResponse) => {
                    return throwError(error);
                })
            );
    }
    setPassword(password:Password):Observable<boolean>{
        let params = new HttpParams()
            .set('token', password.accesToken)
            .set('email', password.email)
            .set('password', password.password)
            .set('confirmPassword', password.confirmPassword);
        return this.http.post<boolean>(apiUrl + 'user/change-password', params)
            .pipe(
                map((res: boolean) => {
                    return res;
                }),
                catchError((error: APIErrorResponse) => {
                    return throwError(error.error.type);
                })
            );
    }
    sendLink(email:string):Observable<boolean>{
        
        let params = new HttpParams()
            .set('grant_type', 'basic')
            .set('email', email);
        return this.http.post<boolean>(apiUrl + 'user/resend-activation-link', params)
            .pipe(
                map((res: boolean) => {
                    return res;
                }),
                catchError((error: APIErrorResponse) => {
                    // console.log(error);
                    
                    return throwError(error.error.type);
                })
            );
    }
    sendResetLink(email:string):Observable<boolean>{
        
        let params = new HttpParams()
            .set('grant_type', 'basic')
            .set('email', email);
            // return of(true)
        return this.http.post<boolean>(apiUrl + 'user/reset-password', params)
            .pipe(
                map((res: boolean) => {
                    return res;
                }),
                catchError((error: APIErrorResponse) => {
                    return throwError(error.error.type);
                })
            );
    }
}
