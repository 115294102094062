import {NgModule} from '@angular/core';
import {AboutRoutingModule} from './about-routing.module';
import {AboutPageComponent} from './containers';
import {AboutComponent} from './components';
import { AccordionModule } from 'ngx-bootstrap/accordion';

export const COMPONENTS = [
    AboutPageComponent,
    AboutComponent,
];

@NgModule({
    imports: [
        AboutRoutingModule,
        AccordionModule.forRoot()
    ],
    declarations: COMPONENTS,
    providers: [
    ]
})
export class AboutModule {
}