import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './core/containers';
import {AuthModule} from './auth';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ROOT_REDUCERS, metaReducers} from './reducers';
import {StoreRouterConnectingModule, RouterState} from '@ngrx/router-store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './auth/effects';
import {HttpClientModule} from '@angular/common/http';
import {RegisterModule} from './register';
import {AboutModule} from './about';
import {ProfileModule} from './profile';
import {FaqModule} from './faq';
import {ContactModule} from './contact';
import {CoreModule} from './core';
import { WelcomeModule } from './welcome';
import { MainModule } from './main';
import { ResetPasswordModule } from './reset-password';
import { ConfirmModule } from './confirm';
import { WithdrawalModule } from './withdrawal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleModule } from './google/google.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        CoreModule,
        AuthModule,
        MainModule,
        AboutModule,
        ProfileModule,
        FaqModule,
        ContactModule,
        WelcomeModule,
        ResetPasswordModule,
        RegisterModule,
        GoogleModule,
        ConfirmModule,
        WithdrawalModule,
        AppRoutingModule,
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true
            },
        }),
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Minimal
        }),
        StoreDevtoolsModule.instrument({
            name: 'diler Go lp',
        }),
        EffectsModule.forRoot([AuthEffects]),
        BrowserAnimationsModule
    ],
    providers: [
        {provide: 'googleTagManagerId', useValue: 'UA-179009744-1'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
